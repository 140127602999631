<!-- <div *ngIf="appComp.isLoad">
    <div id="loading">
        <div class="lds-default">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div> -->
<div class="wrapper">
    <app-header></app-header>
    <!-- content start -->
    <section class="content">
        <div class="row no-gutters">
            <div class="left-column">
                <!-- desktop version -->
                <div class="d-none d-lg-flex h-100 flex-column">
                    <div class="location-on-desk-bx" *ngIf="appComp.locationOn.value === true">
                        <div class="loc-on-map-img" style="
                                background-image: url(./assets/images/listing_bg.webp);
                            "></div>
                        <div class="loc-on-map-cont">
                            <div class="row">
                                <div class="col-3">
                                    <!-- <span class="loc-marker">
                                        <img [src]="
                                                appComp.browserLocation.value
                                                    ? './assets/images/ip-location-marker-new.png'
                                                    : './assets/images/location-marker.png'
                                            " width="109" height="118" alt="" class="img-fluid" />
                                    </span> -->
                                    <span class="loc-marker">
                                        <img [src]="
                                                appComp.browserLocationApac
                                                    ? './assets/images/ip-location-marker-new.webp'
                                                    : './assets/images/location-marker.webp'
                                            " width="109" height="118" loading="lazy" alt="" class="img-fluid" />
                                    </span>
                                </div>
                                <div class="col-6 d-flex align-items-center text-white px-0" style="
                                        border-radius: 8px;
                                        font-size: 0.8rem;
                                    "></div>
                            </div>
                            <span>
                                <div class="tab-thumb clearfix" *ngIf="0">
                                    <ul>
                                        <li [ngClass]="
                                                appComp.discover.value ===
                                                'stores'
                                                    ? 'active'
                                                    : ''
                                            ">
                                            <a (click)="
                                                    toggleDiscovery($event)
                                                " href="Javascript:;" rel="tab1">Store</a>
                                        </li>
                                        <li id="serviceTab" [ngClass]="
                                                appComp.discover.value ===
                                                'service centers'
                                                    ? 'active'
                                                    : ''
                                            ">
                                            <a (click)="
                                                    toggleDiscovery($event)
                                                " href="Javascript:;" rel="tab2">Service</a>
                                        </li>
                                    </ul>
                                </div>
                            </span>
                            <div class="location-white-bx">
                                <div class="custom-dropdown" class="locate-me-txt">
                                    <span class="location-name" *ngIf="appComp.currentLocationName">{{
                                        appComp.currentLocationName }}</span>
                                </div>
                                <div class="search-location d-flex align-items-center">
                                    <img (click)="resetSearch()" loading="lazy"
                                        src="./assets/images/location-marker2.svg" />
                                    <input id="searchLocationDesktop" #searchLocation (keypress)="startSearch($event)"
                                        (keyup)="recordSearchString($event)" type="text" [placeholder]="
                                            'store-listing.placeholder-search-stores'
                                                | translate
                                        " value="" class="textbox" (search)="detectEscape($event)"
                                        [autocomplete]="false" />
                                </div>
                                <div class="turnon-location-bx">
                                    <button type="button" [disabled]="
                                            !searchString ||
                                            searchString.length < 3
                                        " [ngClass]="
                                            !searchString ||
                                            searchString.length < 3
                                                ? 'location-but disabled'
                                                : 'location-but enabled'
                                        " (click)="startSearch($event)">
                                        Search
                                    </button>
                                </div>
                                <div *ngIf="places.length > 0" #placesHolder class="search-places">
                                    <ul>
                                        <li *ngFor="let place of places" [id]="place.place_id" (click)="
                                                getStoreByPlaceId(
                                                    place.place_id,
                                                    place.description,
                                                    place.lat,
                                                    place.lng,
                                                    null
                                                )
                                            ">
                                            {{ place.description }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="offline-map-bx" *ngIf="appComp.locationOn.value === false">
                        <div class="offline-map-img" style="
                                background-image: url(./assets/images/Mask_group.svg);
                            "></div>
                        <div class="offline-map-cont store-service-bx">
                            <div class="tab-section">
                                <div class="tab-content">
                                    <div class="tab-details tab1">
                                        <div class="row">
                                            <div class="col-12 pr-0">
                                                <span class="loc-marker d-inline-block">
                                                    <img src="./assets/images/location-off-icon.png" width="102"
                                                        height="109" alt="" class="img-fluid" loading="lazy" />
                                                </span>
                                                <p class="loc-out-of-reach-text mb-4 d-inline-block">
                                                    Your location is<br />
                                                    out of reach
                                                </p>
                                            </div>
                                            <!-- <div class="col-8 d-flex align-items-center text-white" style="background-color: rgba(0,0,0,0.3); border-radius: 8px; font-size: 0.8rem;">
                                     Turn on location on your browser and refresh.<br><br> Or, click "Locate Me" below to view stores based on your approximate location.
                                     </div> -->
                                        </div>
                                        <div class="location-white-bx" *ngIf="
                                                !this.appComp.countryListShow
                                            ">
                                            <span class="locate-me-txt" (click)="getLocationByIp()">{{
                                                "store-listing.placeholder-locate-me" | translate }}</span>
                                            <div class="search-location d-flex align-items-center">
                                                <img (click)="resetSearch()" src="./assets/images/location-marker2.svg"
                                                    loading="lazy" />
                                                <input id="searchLocationDesktop" #searchLocation (keypress)="
                                                        startSearch($event)
                                                    " (keyup)="
                                                        recordSearchString(
                                                            $event
                                                        )
                                                    " type="text" [placeholder]="
                                                        'store-listing.placeholder-search-stores'
                                                            | translate
                                                    " value="" class="textbox" (search)="
                                                        detectEscape($event)
                                                    " />
                                            </div>
                                            <div class="turnon-location-bx">
                                                <button type="button" [disabled]="
                                                        !searchString ||
                                                        searchString.length < 3
                                                    " [ngClass]="
                                                        !searchString ||
                                                        searchString.length < 3
                                                            ? 'location-but disabled'
                                                            : 'location-but enabled'
                                                    " (click)="
                                                        startSearch($event)
                                                    ">
                                                    Search
                                                </button>
                                            </div>
                                            <div *ngIf="places.length > 0" #placesHolder class="search-places">
                                                <ul>
                                                    <li *ngFor="
                                                            let place of places
                                                        " [id]="place.place_id" (click)="
                                                            getStoreByPlaceId(
                                                                place.place_id,
                                                                place.description,
                                                                place.lat,
                                                                place.lng,
                                                                null
                                                            )
                                                        ">
                                                        {{ place.description }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="popular-cities-bx" *ngIf="
                                                appComp.popCities &&
                                                !this.appComp.countryListShow
                                            ">
                                            <p>
                                                {{
                                                "store-listing.label-popular-cities"
                                                | translate
                                                }}
                                                <br /><a href="javascript:;" *ngFor="
                                                        let city of appComp.popCities
                                                    " (click)="
                                                        getStoresByCity(
                                                            city.lat,
                                                            city.lng,
                                                            city.city
                                                        )
                                                    ">{{ city.city }}</a>
                                            </p>
                                        </div>
                                        <div class="popular-cities-bx" *ngIf="this.appComp.countryListShow">
                                            <p>
                                                {{
                                                "store-listing.tab-title-country-region"
                                                | translate
                                                }}
                                            </p>
                                            <br />
                                            <p style="
                                                    font-weight: 800;
                                                    display: flex;
                                                    justify-content: center;
                                                    flex-wrap: wrap;
                                                ">
                                                <a href="{{country.code}}/en" class="d-flex align-items-center" *ngFor="
                                                        let country of countriesList
                                                    " (click)="
                                                        getCountrySelected(
                                                            country.code,
                                                            country.name
                                                        )
                                                    ">
                                                    <!-- <img [src]="'./assets/flags/4x3/'+country.code+'.svg'" class="mr-1" style="width: 25px;"> -->
                                                    {{ country.name }}</a>
                                            </p>
                                        </div>
                                        <div class="facts-bx">
                                            <p>
                                                <span>#LenovoFacts</span>
                                                {{
                                                "store-listing.label-lenovofacts-text"
                                                | translate
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="
                            !appComp.locationOn.value
                                ? 'store-listing-screen-desk hidden'
                                : 'store-listing-screen-desk'
                        ">
                        <div class="tab-section">
                            <div class="screen-thumb black-color tab-thumb">
                                <ul>
                                    <li class="list active">
                                        <a href="javascript:;" rel="tab1" (click)="
                                                selectSellerType(
                                                    $event,
                                                    'Exclusive',
                                                    0
                                                )
                                            ">{{
                                            "store-listing.tab-title-les"
                                            | translate
                                            }}</a>
                                    </li>
                                    <li class="list">
                                        <a href="javascript:;" rel="tab1" (click)="
                                                selectSellerType(
                                                    $event,
                                                    'Reseller',
                                                    1
                                                )
                                            ">{{
                                            "store-listing.tab-title-mbo"
                                            | translate
                                            }}</a>
                                    </li>
                                    <li class="list">
                                        <a href="javascript:;" rel="tab1" (click)="
                                                selectSellerType(
                                                    $event,
                                                    'Gaming',
                                                    2
                                                )
                                            ">{{
                                            "store-listing.tab-title-gaming"
                                            | translate
                                            }}</a>
                                    </li>
                                    <li class="list">
                                        <a href="javascript:;" rel="tab1" (click)="
                                                selectSellerType($event, '', 3)
                                            ">
                                            <!-- appComp.discover.value
                                            | titlecase
                                            }} -->
                                            {{
                                            "store-listing.tab-title-all"
                                            | translate
                                            }}</a>
                                    </li>
                                    <!--  <li><a href="javascript:;" (click)="toggleTab($event)"  rel="tab2">{{appComp.discover.value | titlecase}} Open Now</a></li>
                               <li *ngIf="appComp.discover.value==='stores'"><a href="javascript:;" rel="tab3">{{appComp.discover.value | titlecase}} With Offers</a></li> -->
                                </ul>
                            </div>
                            <div class="screen-tab-content">
                                <div class="tab-cont tab-details tab1">
                                    <div class="found-result-bx">
                                        <div id="foundStores1" #foundStores1 class="result-txt"
                                            [innerHTML]="foundStore">
                                            Finding stores...
                                        </div>
                                        <a href="javascript:;" *ngIf="mapOffOn" (click)="
                                                viewMap(
                                                    $event,
                                                    'desktop',
                                                    undefined
                                                )
                                            " class="button-blue deskViewMapBut">{{
                                            "store-listing.label-view-on-map"
                                            | translate
                                            }}</a>
                                    </div>
                                    <div class="result-listing-bx" *ngIf="stores">
                                        <ng-container *ngFor="
                                                let store of stores;
                                                let i = index
                                            ">
                                            <!-- <ng-container *ngIf="
                                                    i === 4 &&
                                                    appComp.discover.value ===
                                                        'stores'
                                                ">
                                                <div class="result-item">
                                                    <div #surveyBox2
                                                        class="card-bx flex-column survey-intro-screen justify-content-center">
                                                        <div
                                                            class="d-flex justify-content-center align-items-center justify-content-between">
                                                            <div class="d-flex">
                                                                <span class="col-4 d-flex flex-column">
                                                                    <span class="survey-title">Take
                                                                        Our
                                                                        Survey</span>
                                                                    <img src="./assets/images/survey-img.png"
                                                                        width="165" height="128" alt=""
                                                                        class="img-fluid" />
                                                                </span>
                                                                <span class="col-8 d-flex flex-column">
                                                                    <h4 class="text-center">
                                                                        How do
                                                                        you rate
                                                                        your
                                                                        experience
                                                                        of
                                                                        finding
                                                                        a nearby
                                                                        Lenovo
                                                                        store?
                                                                    </h4>
                                                                    <div class="survey-number-bx survey-content pt-3">
                                                                        <div class="row">
                                                                            <div class="col-md-12 d-flex flex-column">

                                                                                <div class="radio-bx">
                                                                                    <div class="desktop-rating-wrapper"
                                                                                        *ngIf="
                                                                                            currentItem >
                                                                                            0
                                                                                        ">

                                                                                        <app-emoji-scale (rateEvent)="
                                                                                                initSurvey(
                                                                                                    $event
                                                                                                )
                                                                                            " [ratingLength]="
                                                                                                currentItem
                                                                                            " [ratingString]="
                                                                                                ratingString
                                                                                            "></app-emoji-scale>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container> -->
                                            <ng-container>
                                                <div class="result-item">
                                                    <div class="card-bx flex-column">
                                                        <div class="row p-0 m-0 mb-3">
                                                            <div class="col-9 pr-1 pl-0 mb-3">
                                                                <h4 class="pb-1 d-flex align-items-top">
                                                                    {{
                                                                    store.displayName
                                                                    }}

                                                                    <!-- <span *ngIf="store.sellertype==='exclusive'">Lenovo Exclusive Store</span> -->
                                                                </h4>
                                                                <a href="javascript:;" (click)="
                                                                            share(
                                                                                store
                                                                            )
                                                                        " class="share-icon mt-1">&nbsp;</a>
                                                            </div>
                                                            <div class="col-3 pr-2 text-right">
                                                                <a *ngIf="
                                                                        store.gmapLink
                                                                    " href="{{
                                                                        store.gmapLink
                                                                    }}" target="_blank" class="directions p-0">{{
                                                                    "store-listing.store-card.link-get-directions"
                                                                    | translate
                                                                    }}</a>
                                                            </div>
                                                        </div>
                                                        <div class="row p-0 m-0 mb-3">
                                                            <div *ngIf="
                                                                    store &&
                                                                    (store?.timings ||
                                                                        store.tmpMessage)
                                                                " [ngClass]="
                                                                    !store.tmpMessage
                                                                        ? 'col-5 p-0'
                                                                        : 'col-8 p-0'
                                                                ">
                                                                <p *ngIf="
                                                                        store?.tmpMessage
                                                                    " class="mb-3 temp_msg" style="
                                                                        color: #4ac0e0;
                                                                    ">
                                                                    {{
                                                                    store.tmpMessage
                                                                    }}
                                                                </p>
                                                                <span class="store-time open-icon" *ngIf="
                                                                        store?.timings
                                                                    ">
                                                                    {{
                                                                    "store-listing.store-card.business-hours"
                                                                    | translate
                                                                    }}
                                                                    -

                                                                    {{
                                                                    utily.getTodayStoreTimings(
                                                                    store?.timings
                                                                    )
                                                                    }}
                                                                </span>
                                                            </div>
                                                            <div class="col-4" style="
                                                                    padding-left: 0px;
                                                                ">
                                                                <strong><span class="distance-txt">{{
                                                                        store?.distance
                                                                        | number
                                                                        : "1.2-2"
                                                                        }}
                                                                        km
                                                                        away</span></strong>
                                                            </div>
                                                        </div>
                                                        <div class="row no-gutters">
                                                            <div class="col-8 pr-1">
                                                                <p class="pb-0">
                                                                    {{
                                                                    store.address
                                                                    }}<br />{{
                                                                    store
                                                                    ?.city

                                                                    ? store
                                                                    ?.city

                                                                    : ""
                                                                    }}
                                                                    {{
                                                                    store
                                                                    ?.state

                                                                    ? ", " +
                                                                    store
                                                                    ?.state

                                                                    : ""
                                                                    }}
                                                                    {{
                                                                    store?.pincode
                                                                    ? ", " +
                                                                    store?.pincode
                                                                    : ""
                                                                    }}
                                                                </p>
                                                                <p>
                                                                    <a *ngIf="
                                                                            store.maskingNum &&
                                                                            appComp
                                                                                .discover
                                                                                .value ===
                                                                                'stores'
                                                                        " class="call-txt" href="tel:{{
                                                                            store.maskingNum
                                                                        }}">{{
                                                                        "store-listing.store-card.call-store"
                                                                        | translate
                                                                        }}
                                                                        -
                                                                        {{
                                                                        store.maskingNum
                                                                        }}</a>
                                                                    <a *ngIf="
                                                                            store.maskingNum &&
                                                                            appComp
                                                                                .discover
                                                                                .value ===
                                                                                'service centers'
                                                                        " class="call-txt" href="tel:{{
                                                                            store.maskingNum
                                                                        }}">{{
                                                                        "store-listing.store-card.call-store"
                                                                        | translate
                                                                        }}
                                                                        -
                                                                        {{
                                                                        store.maskingNum
                                                                        }}</a>
                                                                </p>
                                                                <p>
                                                                    <a *ngIf="
                                                                            store?.formattedAddress
                                                                        " href="/{{
                                                                            appComp.countryName
                                                                        }}/{{
                                                                            getLang()
                                                                        }}/store/{{
                                                                            store.formattedAddress
                                                                        }}" class="view-store">{{
                                                                        "store-listing.store-card.view-more-details"
                                                                        | translate
                                                                        }}</a>
                                                                </p>
                                                            </div>
                                                            <div class="col-4">
                                                                <div class="clear-both" *ngIf="
                                                                        store.buyOnlineLink &&
                                                                        appComp
                                                                            .discover
                                                                            .value ===
                                                                            'stores'
                                                                    ">
                                                                    <!-- <a href="{{
                                                                            store?.buyOnlineLink
                                                                        }}" class="button-gradient cta-buy-post"
                                                                        target="_blank">Buy
                                                                        Online</a> -->
                                                                </div>
                                                                <div class="clear-both">
                                                                    <a *ngIf="
                                                                            store?.bookademo ==
                                                                            true
                                                                        " target="_blank"
                                                                        href="/bookademo?cid=in:other:9o8v9h"
                                                                        class="button-gradient cta-book-post">Book a
                                                                        Demo</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div id="morestorelistholder">
                                        <div id="storeGallery" class="result-listing-bx"></div>
                                    </div>
                                    <div id="storelistBeyondRadiusholder">
                                        <div id="storeBeyondRadiusGallery" class="result-listing-bx"></div>
                                    </div>
                                    <div class="text-center" (click)="getMoreStores(25)" *ngIf="
                                            this.appComp.loadMore ==
                                            this.appComp.record
                                        ">
                                        <a href="javascript:;" style="color: #1b2b81">Load More</a>
                                    </div>
                                </div>
                                <div class="tab-cont tab-details tab2">
                                    <div class="found-result-bx">
                                        <span class="result-txt">Found more than 30 stores in Thane
                                            West</span>
                                        <a href="javascript:;" class="button-blue deskViewMapBut">{{
                                            "store-listing.label-view-on-map"
                                            | translate
                                            }}</a>
                                    </div>
                                    <div class="result-listing-bx">
                                        <div class="result-item">
                                            <div class="card-bx flex-column">
                                                <h4>
                                                    Ace Infocom X
                                                    <a href="javascript:;" class="share-icon">&nbsp;</a>
                                                    <span>Lenovo Exclusive
                                                        Store</span>
                                                </h4>
                                                <div class="row no-gutters">
                                                    <div class="col-8">
                                                        <p>
                                                            Shop No. 41, Heera
                                                            Panna Mall, Near Jal
                                                            Vayu Vihar, Powai,
                                                            Mumbai City,
                                                            Maharashtra 400065
                                                        </p>
                                                        <span class="call-txt">Call Store - 022
                                                            54327788</span>
                                                        <span class="store-time open-icon">Open till
                                                            9.30pm</span>
                                                    </div>
                                                    <div class="col-4 text-right">
                                                        <span class="distance-txt">0.01 km away</span>
                                                        <a href="https://www.google.com/" class="button-gradient">{{
                                                            "store-listing.store-card.link-get-directions"
                                                            | translate
                                                            }}</a>
                                                        <div class="clear-both">
                                                            <a href="javascript:;" class="view-store">View Store
                                                                Website</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="result-item">
                                            <div class="card-bx flex-column">
                                                <h4>
                                                    Ace Infocom 2
                                                    <a href="javascript:;" class="share-icon">&nbsp;</a>
                                                    <span>Lenovo Exclusive
                                                        Store</span>
                                                </h4>
                                                <div class="row no-gutters">
                                                    <div class="col-8">
                                                        <p>
                                                            Shop No. 41, Heera
                                                            Panna Mall, Near Jal
                                                            Vayu Vihar, Powai,
                                                            Mumbai City,
                                                            Maharashtra 400065
                                                        </p>
                                                        <span class="call-txt">Call Store - 022
                                                            54327788</span>
                                                        <span class="store-time open-icon">Open till
                                                            9.30pm</span>
                                                    </div>
                                                    <div class="col-4 text-right">
                                                        <span class="distance-txt">0.01 km away</span>
                                                        <a href="https://www.google.com/" class="button-gradient">{{
                                                            "store-listing.store-card.link-get-directions"
                                                            | translate
                                                            }}</a>
                                                        <div class="clear-both">
                                                            <a href="javascript:;" class="view-store">View Store
                                                                Website</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="result-item">
                                            <div class="card-bx flex-column">
                                                <h4>
                                                    Ace Infocom 3
                                                    <a href="javascript:;" class="share-icon">&nbsp;</a>
                                                    <span>Lenovo Exclusive
                                                        Store</span>
                                                </h4>
                                                <div class="row no-gutters">
                                                    <div class="col-8">
                                                        <p>
                                                            Shop No. 41, Heera
                                                            Panna Mall, Near Jal
                                                            Vayu Vihar, Powai,
                                                            Mumbai City,
                                                            Maharashtra 400065
                                                        </p>
                                                        <span class="call-txt">Call Store - 022
                                                            54327788</span>
                                                        <span class="store-time open-icon">Open till
                                                            9.30pm</span>
                                                    </div>
                                                    <div class="col-4 text-right">
                                                        <span class="distance-txt">0.01 km away</span>
                                                        <a href="https://www.google.com/" class="button-gradient">{{
                                                            "store-listing.store-card.link-get-directions"
                                                            | translate
                                                            }}</a>
                                                        <div class="clear-both">
                                                            <a href="javascript:;" class="view-store">View Store
                                                                Website</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-cont tab-details tab3">
                                    <div class="found-result-bx">
                                        <span class="result-txt">Found more than 40 stores in Thane
                                            West</span>
                                        <a href="javascript:;" class="button-blue deskViewMapBut">{{
                                            "store-listing.label-view-on-map"
                                            | translate
                                            }}</a>
                                    </div>
                                    <div class="result-listing-bx">
                                        <div class="result-item">
                                            <div class="card-bx flex-column">
                                                <h4>
                                                    Ace Infocom 4
                                                    <a href="javascript:;" class="share-icon">&nbsp;</a>
                                                    <span>Lenovo Exclusive
                                                        Store</span>
                                                </h4>
                                                <div class="row no-gutters">
                                                    <div class="col-8">
                                                        <p>
                                                            Shop No. 41, Heera
                                                            Panna Mall, Near Jal
                                                            Vayu Vihar, Powai,
                                                            Mumbai City,
                                                            Maharashtra 400065
                                                        </p>
                                                        <span class="call-txt">Call Store - 022
                                                            54327788</span>
                                                        <span class="store-time open-icon">Open till
                                                            9.30pm</span>
                                                    </div>
                                                    <div class="col-4 text-right">
                                                        <span class="distance-txt">0.01 km away</span>
                                                        <a href="https://www.google.com/" class="button-gradient">{{
                                                            "store-listing.store-card.link-get-directions"
                                                            | translate
                                                            }}</a>
                                                        <div class="clear-both">
                                                            <a href="javascript:;" class="view-store">View Store
                                                                Website</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="result-item">
                                            <div class="card-bx flex-column">
                                                <h4>
                                                    Ace Infocom 5
                                                    <a href="javascript:;" class="share-icon">&nbsp;</a>
                                                    <span>Lenovo Exclusive
                                                        Store</span>
                                                </h4>
                                                <div class="row no-gutters">
                                                    <div class="col-8">
                                                        <p>
                                                            Shop No. 41, Heera
                                                            Panna Mall, Near Jal
                                                            Vayu Vihar, Powai,
                                                            Mumbai City,
                                                            Maharashtra 400065
                                                        </p>
                                                        <span class="call-txt">Call Store - 022
                                                            54327788</span>
                                                        <span class="store-time open-icon">Open till
                                                            9.30pm</span>
                                                    </div>
                                                    <div class="col-4 text-right">
                                                        <span class="distance-txt">0.01 km away</span>
                                                        <a href="https://www.google.com/" class="button-gradient">{{
                                                            "store-listing.store-card.link-get-directions"
                                                            | translate
                                                            }}</a>
                                                        <div class="clear-both">
                                                            <a href="javascript:;" class="view-store">View Store
                                                                Website</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="result-item">
                                            <div class="card-bx flex-column">
                                                <h4>
                                                    Ace Infocom 6<a href="javascript:;" class="share-icon">&nbsp;</a>
                                                    <span>Lenovo Exclusive
                                                        Store</span>
                                                </h4>
                                                <div class="row no-gutters">
                                                    <div class="col-8">
                                                        <p>
                                                            Shop No. 41, Heera
                                                            Panna Mall, Near Jal
                                                            Vayu Vihar, Powai,
                                                            Mumbai City,
                                                            Maharashtra 400065
                                                        </p>
                                                        <span class="call-txt">Call Store - 022
                                                            54327788</span>
                                                        <span class="store-time open-icon">Open till
                                                            9.30pm</span>
                                                    </div>
                                                    <div class="col-4 text-right">
                                                        <span class="distance-txt">0.01 km away</span>
                                                        <a href="https://www.google.com/" class="button-gradient">{{
                                                            "store-listing.store-card.link-get-directions"
                                                            | translate
                                                            }}</a>
                                                        <div class="clear-both">
                                                            <a href="javascript:;" class="view-store">View Store
                                                                Website</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="deskMapContainer" class="map-container h-100 deskMapContainer">
                        <!-- <div class="search-location-bx">
                   <div class="location-white-bx">
                       <div class="custom-dropdown">
                           <select>
                               <option value="stores">Stores</option>
                               <option value="service" selected>Service</option>
                           </select>
                       </div>
                       <div class="search-location d-flex align-items-center">
                           <img src="./assets/images/location-marker2.webp">
                           <input #searchLocation (keypress)="startSearch($event)" type="text" placeholder="Search Location" value="" class="textbox">
                       </div>
                       <div class="turnon-location-bx">
                           <button type="button" class="location-but">Search</button>
                       </div>
                   </div>
                   </div> -->
                        <div class="tab-section">
                            <!-- <div class="turnon-location-bx">
                      <button type="button" [disabled]="!searchString || searchString.length<3"
                          [ngClass]="(!searchString || searchString.length<3)?'location-but disabled':'location-but enabled'"
                          (click)="startSearch($event)">Search</button>
                      </div> -->
                            <div class="screen-thumb black-color tab-thumb">
                                <ul>
                                    <li class="active" (click)="toggleTab($event)">
                                        <a href="javascript:;" rel="tab1">All Stores</a>
                                    </li>
                                    <!-- <li (click)="toggleTab($event)" ><a href="javascript:;" rel="tab2" (click)="toggleTab($event)">Stores Open Now</a></li>
                            <li><a href="javascript:;" rel="tab3">Stores With Offers</a></li> -->
                                </ul>
                                <span #mapResultText class="result-txt"></span>
                            </div>
                            <div class="tab-content">
                                <div class="tab-cont tab-details tab1">
                                    <div id="map_canvas" style="
                                            position: absolute;
                                            width: 100%;
                                            height: 100%;
                                        "></div>
                                </div>
                                <!-- <div class="tab-cont tab-details tab2">
                         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241505.2523226936!2d72.68476514995965!3d18.952648027803843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9dc81753c1b%3A0xe9ab9faa1ddbc947!2sLenovo%20Exclusive%20Store%20-%20Ace%20Infocom!5e0!3m2!1sen!2sin!4v1616914446433!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" class="map-iframe"></iframe>
                         </div>

                         <div class="tab-cont tab-details tab3">
                         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241505.2523226936!2d72.68476514995965!3d18.952648027803843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9dc81753c1b%3A0xe9ab9faa1ddbc947!2sLenovo%20Exclusive%20Store%20-%20Ace%20Infocom!5e0!3m2!1sen!2sin!4v1616914446433!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" class="map-iframe"></iframe>
                         </div> -->
                            </div>
                        </div>
                        <a href="javascript:;" (click)="closeMap()" class="view-button deskViewOnList">{{
                            "store-listing.label-view-on-list" | translate
                            }}</a>
                    </div>
                </div>
                <!-- desktop version -->
                <!-- mobile version -->
                <div class="store-service-bx d-lg-none">
                    <div class="container">
                        <div class="tab-section">
                            <div class="tab-thumb clearfix" *ngIf="appComp.locationOn.value">
                                <!-- <ul>
                                    <li [ngClass]="
                                            appComp.discover.value === 'stores'
                                                ? 'active'
                                                : ''
                                        ">
                                        <a (click)="toggleDiscovery($event)" href="Javascript:;" rel="tab1">Store</a>
                                    </li>
                                    <li [ngClass]="
                                            appComp.discover.value ===
                                            'service centers'
                                                ? 'active'
                                                : ''
                                        ">
                                        <a id="serviceTab" (click)="toggleDiscovery($event)" href="Javascript:;"
                                            rel="tab2">Service</a>
                                    </li>
                                </ul> -->
                            </div>
                            <div class="tab-content">
                                <div #tabContStores id="tabContStores" class="tab-details tab1">
                                    <div class="location-tab-bx">
                                        <span class="section-heading"><span class="store-icon"></span>discover
                                            stores</span>
                                        <div [ngClass]="
                                                appComp.locationOn.value
                                                    ? 'location-bx location-on-bx'
                                                    : 'location-bx location-off-bx'
                                            ">
                                            <div class="map-bx">
                                                <div class="turn-on-location">
                                                    <div class="turn-on-loc-inner">
                                                        <label class="switch" *ngIf="
                                                                appComp
                                                                    .browserLocation
                                                                    .value
                                                            ">
                                                            <input type="checkbox" disabled [checked]="
                                                                    appComp
                                                                        .browserLocation
                                                                        .value
                                                                " />
                                                            <!-- <span class="slider round"></span> -->
                                                        </label>
                                                        <span class="turn-on-desp" [ngStyle]="{
                                                                color:
                                                                    appComp
                                                                        .browserLocation
                                                                        .value ===
                                                                    true
                                                                        ? 'white'
                                                                        : 'white'
                                                            }">{{
                                                            !appComp
                                                            .browserLocation
                                                            .value
                                                            ? ""
                                                            : ""
                                                            }}</span>
                                                    </div>
                                                    <!-- <span class="current-location">Location On - Thane West</span> -->
                                                </div>
                                                <!-- <span class="loc-marker">
                                                    <img [src]="
                                                            appComp
                                                                .browserLocation
                                                                .value
                                                                ? './assets/images/ip-location-marker-new.png'
                                                                : './assets/images/location-marker.png'
                                                        " width="109" height="118" alt="" class="img-fluid" />
                                                </span> -->
                                                <span class="loc-marker">
                                                    <img [src]="
                                                            appComp.browserLocationApac
                                                                ? './assets/images/ip-location-marker-new.webp'
                                                                : './assets/images/location-marker.webp'
                                                        " width="109" height="118" alt="" class="img-fluid"
                                                        loading="lazy" />
                                                </span>
                                                <span id="foundStores2" #foundStores2 class="found-result-txt" *ngIf="
                                                        appComp.locationOn.value
                                                    " [innerHTML]="foundStore">Finding stores...</span>
                                                <span class="loc-off-facts" *ngIf="
                                                        !appComp.locationOn
                                                            .value
                                                    ">#LenovoFacts<br />{{
                                                    "store-listing.label-lenovofacts-text"
                                                    | translate
                                                    }}</span>
                                                <a href="javascript:;" class="button-blue" id="viewStoreListing" *ngIf="
                                                        appComp.locationOn.value
                                                    " (click)="showStoreListing()">{{
                                                    appComp.discover
                                                    .value === "stores"
                                                    ? translate.instant('store-listing.label-view-stores')
                                                    : "View Service Centers"
                                                    }}</a>

                                                <a href="javascript:;" class="button-white" *ngIf="
                                                        !appComp.locationOn
                                                            .value &&
                                                        !this.appComp
                                                            .countryListShow && this.appComp.popCities
                                                    " (click)="locateMe()">{{ "store-listing.placeholder-locate-me" |
                                                    translate }}</a>
                                                <!-- *ngIf="
                                                this.appComp
                                                    .countryListShow
                                            " -->
                                                <a href="javascript:;" class="button-white" (click)="showCountries()"
                                                    *ngIf="
                                                this.appComp
                                                    .countryListShow && !this.appComp.popCities
                                            ">Supported Countries</a>
                                            </div>
                                            <div class="search-change-loc-bx">
                                                <div class="input-bx">
                                                    <input id="mobilePlacesSearchInput1" type="text" value=""
                                                        (keypress)="
                                                            startMobileSearch(
                                                                $event
                                                            )
                                                        " (click)="
                                                            showMobileSearchWindow(
                                                                $event
                                                            )
                                                        "
                                                        placeholder="{{'store-listing.placeholder-search-stores' | translate}}"
                                                        class="text-field" autocomplete="off" />
                                                    <input type="button" value="" class="search-button"
                                                        id="mobilePlacesSearchButton3" (click)="
                                                            startMobileSearch(
                                                                $event
                                                            )
                                                        " />
                                                    <div id="mobileSearchDropdown1"
                                                        class="search-dropdown searchDropdown">
                                                        <a href="#" class="detect-location" (click)="
                                                                setCurrentLocation()
                                                            ">
                                                            <span> {{ "store-listing.detect-current-location" |
                                                                translate }}</span>
                                                        </a>
                                                        <div class="search-but-bx">
                                                            <button id="mobilePlacesSearchButton1" (click)="
                                                                    startMobileSearch(
                                                                        $event
                                                                    )
                                                                " [ngClass]="
                                                                    searchString
                                                                        ? 'button-gradient searchLocation'
                                                                        : 'button-gradient disabled searchLocation'
                                                                ">
                                                                {{ "store-listing.label-search" | translate }}
                                                            </button>
                                                            <span class="search-note">{{
                                                                "store-listing.label-search-bldg" | translate }}</span>
                                                        </div>
                                                    </div>
                                                    <div id="mobilSearchListing1"
                                                        class="search-dropdown search-listing clearfix searchListing">
                                                        <ul class="listing clearfix">
                                                            <li *ngFor="
                                                                    let place of places
                                                                " class="p-1">
                                                                <a (click)="
                                                                        getStoresByPlaceChange(
                                                                            place.place_id,
                                                                            place.description,
                                                                            place.lat,
                                                                            place.lng,
                                                                            $event
                                                                        )
                                                                    ">{{
                                                                    place.description
                                                                    }}</a>
                                                            </li>
                                                            <!-- <li>
                                              <a href="javascript:;">Thane Railway Station, Maharashtra</a>
                                              </li>
                                              <li>
                                              <a href="javascript:;">Union Bank of India, Thane West</a>
                                              </li> -->
                                                        </ul>
                                                        <!-- <a href="#" class="detect-location">
                                           <span>

                                           </span>
                                           </a> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div #tabContServiceCenters id="tabContServiceCenters" class="tab-details tab2">
                                    <div class="location-tab-bx">
                                        <span class="section-heading"><span class="service-icon"></span>discover service
                                            centres</span>
                                        <div [ngClass]="
                                                appComp.locationOn.value
                                                    ? 'location-bx location-on-bx'
                                                    : 'location-bx location-off-bx'
                                            ">
                                            <div class="map-bx">
                                                <div class="turn-on-location">
                                                    <div class="turn-on-loc-inner">
                                                        <label class="switch" *ngIf="
                                                                appComp
                                                                    .browserLocation
                                                                    .value
                                                            ">
                                                            <input type="checkbox" disabled [checked]="
                                                                    appComp
                                                                        .browserLocation
                                                                        .value
                                                                " />
                                                            <!--  <span class="slider round"></span> -->
                                                        </label>
                                                        <span class="turn-on-desp" [ngStyle]="{
                                                                color:
                                                                    appComp
                                                                        .browserLocation
                                                                        .value ===
                                                                    true
                                                                        ? 'white'
                                                                        : 'white'
                                                            }">{{
                                                            !appComp
                                                            .browserLocation
                                                            .value
                                                            ? "Turn
                                                            Precise Location On for better results"
                                                            : "Location
                                                            on"
                                                            }}</span>
                                                    </div>
                                                    <!-- <span class="current-location">Location On - Thane West</span> -->
                                                </div>
                                                <span class="loc-marker">
                                                    <img [src]="
                                                            appComp
                                                                .browserLocation
                                                                .value
                                                                ? './assets/images/ip-location-marker-new.webp'
                                                                : './assets/images/location-marker.webp'
                                                        " width="109" height="118" alt="" class="img-fluid"
                                                        loading="lazy" />
                                                </span>
                                                <span id="foundServiceCenters" #foundServiceCenters
                                                    class="found-result-txt" *ngIf="
                                                        appComp.locationOn.value
                                                    "></span>
                                                <span class="loc-off-facts" *ngIf="
                                                        !appComp.locationOn
                                                            .value
                                                    ">#LenovoFacts<br />{{
                                                    "store-listing.label-lenovofacts-text"
                                                    | translate
                                                    }}</span>
                                                <a href="javascript:;" id="viewServiceCentersListing" *ngIf="
                                                        appComp.locationOn.value
                                                    " class="button-white" (click)="
                                                        showServiceCenterListing()
                                                    ">View Service Centres</a>
                                                <a href="javascript:;" class="button-white" *ngIf="
                                                        !appComp.locationOn
                                                            .value
                                                    " (click)="locateMe()">{{ "store-listing.placeholder-locate-me" |
                                                    translate }}</a>
                                            </div>
                                            <div class="search-change-loc-bx">
                                                <div class="input-bx">
                                                    <input id="mobilePlacesSearchInput2" type="text" value=""
                                                        (keypress)="
                                                            startMobileSearch(
                                                                $event
                                                            )
                                                        " (click)="
                                                            showMobileSearchWindow(
                                                                $event
                                                            )
                                                        "
                                                        placeholder="{{'store-listing.placeholder-search-stores' | translate}}"
                                                        class="text-field" />
                                                    <input type="button" value="" class="search-button" />
                                                    <div id="mobileSearchDropdown2"
                                                        class="search-dropdown searchDropdown">
                                                        <a href="#" class="detect-location" (click)="
                                                                setCurrentLocation()
                                                            ">
                                                            <span>{{ "store-listing.detect-current-location" | translate
                                                                }}</span>
                                                        </a>
                                                        <div class="search-but-bx">
                                                            <button id="mobilePlacesSearchButton2" (click)="
                                                                    startMobileSearch(
                                                                        $event
                                                                    )
                                                                " [ngClass]="
                                                                    searchString
                                                                        ? 'button-gradient searchLocation'
                                                                        : 'button-gradient disabled searchLocation'
                                                                ">
                                                                {{ "store-listing.label-search" | translate }}
                                                            </button>
                                                            <span class="search-note">{{
                                                                "store-listing.label-search-bldg" | translate }}</span>
                                                        </div>
                                                    </div>
                                                    <div id="mobilSearchListing2"
                                                        class="search-dropdown search-listing clearfix searchListing">
                                                        <ul class="listing clearfix">
                                                            <li *ngFor="
                                                                    let place of places
                                                                " class="p-1">
                                                                <a (click)="
                                                                        getStoreByPlaceId(
                                                                            place.place_id,
                                                                            place.description,
                                                                            place.lat,
                                                                            place.lng,
                                                                            $event
                                                                        )
                                                                    ">{{
                                                                    place.description
                                                                    }}</a>
                                                            </li>
                                                        </ul>
                                                        <a href="#" class="detect-location">
                                                            <span>{{ "store-listing.detect-current-location" | translate
                                                                }}</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- mobile version -->
            </div>
            <!-- Location Off -->
            <!-- <div class="left-column" *ngIf="appComp.locationOn.value === false">
          <div class="d-none d-lg-flex h-100 offline-map-bx">
              <div class="offline-map-img" style="background-image: url(./assets/images/map-desk-off.png);"></div>
              <div class="offline-map-cont store-service-bx">

                  <div class="tab-section">
                      <div class="tab-thumb clearfix">
                          <ul>
                              <li [ngClass]="appComp.discover.value==='stores'?'active':''"><a href="Javascript:;" (click)="toggleDiscovery($event)" rel="tab1">Store1</a></li>
                              <li [ngClass]="appComp.discover.value==='service centers'?'active':''"><a (click)="toggleDiscovery($event)" href="Javascript:;" rel="tab2">Service</a></li>
                          </ul>
                      </div>
                      <div class="tab-content">
                          <div class="tab-details tab1">
                              <span class="loc-marker">
                                  <img src="./assets/images/location-off-icon.png" width="102" height="109" alt=""
                                      class="img-fluid">
                              </span>

                              <div class="location-white-bx">
                                  <span class="locate-me-txt" (click)='getLocationByIp()'>{{ "store-listing.placeholder-locate-me" | translate }}</span>
                                  <div class="search-location d-flex align-items-center">
                                      <img (click)="resetSearch()" src="./assets/images/location-marker2.png">
                                      <input id="searchLocationDesktop" #searchLocation (keypress)="startSearch($event)" autocomplete="off"
                                          (keyup)="recordSearchString($event)" type="text" placeholder="Search Location"
                                          value="" class="textbox" (search)="detectEscape($event)">
                                  </div>
                                  <div class="turnon-location-bx">
                                      <button type="button" [disabled]="!searchString || searchString.length<3"
                                          [ngClass]="(!searchString || searchString.length<3)?'location-but disabled':'location-but enabled'"
                                          (click)="startSearch($event)">Search</button>
                                  </div>
                                  <div *ngIf="places.length>0" #placesHolder class="search-places">
                                      <ul>
                                          <li *ngFor="let place of places" [id]="place.place_id"
                                              (click)="getStoreByPlaceId(place.place_id,place.description,null)">
                                              {{place.description}}</li>
                                      </ul>
                                  </div>
                              </div>

                              <div class="popular-cities-bx" *ngIf="appComp.popCities">
                                  <p>Popular Cities: <br /><a href="javascript:;" *ngFor = "let city of appComp.popCities" (click)="getStoresByCity(city.lat, city.lng,city.city)">{{city.city}}</a>
                              </div>

                              <div class="facts-bx">
                                  <p><span>#LenovoFacts</span> 93% of our visitors have their location turned on!
                                  </p>
                              </div>
                          </div> -->
            <!-- <div class="tab-details tab2">
          <span class="loc-marker">
              <img src="images/location-off-icon.png" width="102" height="109" alt=""
                  class="img-fluid">
          </span>

          <div class="location-white-bx">
              <span class="locate-me-txt">{{ "store-listing.placeholder-locate-me" | translate }}</span>
              <div class="search-location">
                  <input type="text" placeholder="Search Location" value="" class="textbox">
              </div>
              <div class="turnon-location-bx">
                  <button type="button" class="location-but">Search</button>
              </div>
              <div class="search-location-dropdown">
                  <ul>
                      <li><a href="#">Bengaluru, India</a></li>
                      <li><a href="#">Belgaum, Karnataka, India</a></li>
                      <li><a href="#">Barielly, Uttar Pradesh</a></li>
                  </ul>
              </div>
          </div>

          <div class="popular-cities-bx">
              <p>Popular Cities: <br /><a href="javascript:;">Mumbai</a> <a
                      href="javascript:;">Bangalore</a> <a href="javascript:;">Pune</a> <a
                      href="javascript:;">Delhi</a> <a href="javascript:;">Hyderbad</a> <a
                      href="javascript:;">Chennai</a></p>
          </div>

          <div class="facts-bx">
              <p><span>#LenovoFacts</span> 93% of our visitors have their location turned on!
              </p>
          </div>
          </div> -->
            <!-- </div>
          </div>

          </div>
          </div>
          </div> -->
            <!-- Location Off -->
            <div class="right-column">
                <div class="section-bg-color h-100 d-tlr-pad">
                    <span class="scroll-more d-lg-none">{{
                        "store-listing.scroll-for-more"
                        | translate
                        }} <span class="arrow-tb-icon"></span></span>
                    <div class="view-offer-bx">
                        <div class="container">
                            <h2 class="section-title">
                                {{
                                "store-listing.section-title-whats-latest"
                                | translate
                                }}
                            </h2>
                            <div #offerGallery [ngClass]="
                                    isDesktop
                                        ? 'offer-gallery p-2 arrow-up'
                                        : 'offer-gallery p-2 arrow-down'
                                " id="offerGallery">
                                <app-apac-offers #whatsLatest *ngIf="banners.length > 0"
                                    [height]="offersHeight"></app-apac-offers>

                                <div *ngIf="banners?.length == 0" style="
                                        position: relative;
                                        width: 100%;
                                        height: 300px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    ">
                                    <p>Forthcoming</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="view-offer-bx">
                        <div class="container assistance_placeholder">
                            <div #offerGallery [ngClass]="
                                    isDesktop
                                        ? 'offer-gallery p-2 arrow-up'
                                        : 'offer-gallery p-2 arrow-down'
                                " id="offerGallery">

                                    <app-get-assistance [country]="appComp.countryName" #whatsLatest></app-get-assistance>


                            </div>
                            <div class="thumbnail-gallery-bx">
                                <div class="gallery-thumb">
                                    <ul id="thumb-list_holder" class="clearfix"></ul>
                                    <a href="javascript:;" class="prev-arrow"></a>
                                    <a href="javascript:;" class="next-arrow"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <app-assistance [height]="assistanceHeight"></app-assistance> -->
                </div>
            </div>
        </div>
    </section>
    <!-- content end -->
    <app-footer></app-footer>
    <!-- store screen start -->
    <div class="store-listing-screen" id="storeListingScreen">
        <div class="container">
            <a (click)="closeViewListingScreen('all')" href="javascript:;"
                class="close-listing-screen closeListingScreen"></a>
            <a href="/" class="logo-vertical">
                <img src="./assets/images/lenovo-logo-vert.webp" width="30" height="85" alt="" loading="lazy" />
            </a>
            <div class="tab-section">
                <div class="screen-thumb tab-thumb">
                    <ul>
                        <li class="list1 active">
                            <a href="javascript:;" rel="tab1" (click)="
                                    selectSellerTypeMob($event, 'Exclusive', 0)
                                ">{{
                                "store-listing.tab-title-les" | translate
                                }}</a>
                        </li>
                        <li class="list1">
                            <a href="javascript:;" rel="tab1" (click)="
                                    selectSellerTypeMob($event, 'Reseller', 1)
                                ">{{
                                "store-listing.tab-title-mbo" | translate
                                }}</a>
                        </li>
                        <li class="list1">
                            <a href="javascript:;" rel="tab1" (click)="
                                    selectSellerTypeMob($event, 'Gaming', 2)
                                ">{{
                                "store-listing.tab-title-gaming" | translate
                                }}</a>
                        </li>
                        <li class="list1">
                            <a href="javascript:;" rel="tab1" (click)="selectSellerTypeMob($event, '', 3)">
                                <!-- appComp.discover.value
                                | titlecase
                                }} -->
                                {{
                                "store-listing.tab-title-all" | translate
                                }}</a>
                        </li>
                        <!--  <li><a href="javascript:;" (click)="toggleTab($event)"  rel="tab2">{{appComp.discover.value | titlecase}} Open Now</a></li>
                   <li *ngIf="appComp.discover.value==='stores'"><a href="javascript:;" rel="tab3">{{appComp.discover.value | titlecase}} With Offers</a></li> -->
                    </ul>
                    <!-- <ul>
                        <li class="active">
                            <a href="javascript:;" rel="tab1">{{ appComp.discover.value | titlecase }} Near
                                You</a>
                        </li>
                                             <li><a href="javascript:;" rel="tab2">Exclusive Stores</a></li>
                   <li><a href="javascript:;" rel="tab3">Multi-Brand Outlets</a></li>
                    </ul> -->
                </div>
                <div class="screen-tab-content">
                    <div class="tab-cont tab-details tab1">
                        <div id="cardsPlaceHolder" class="card-group-bx">
                            <div *ngFor="let store of stores; let i = index" id="card-{{ i }}">
                                <ng-container>
                                    <div class="card-big-bx">
                                        <div class="white-bg-bx">
                                            <div class="top-section">
                                                <span class="store-name">
                                                    {{
                                                    store?.displayName
                                                    }}</span>
                                            </div>
                                            <div class="btm-section">
                                                <address>
                                                    {{ store?.address }}<br />
                                                    {{ store?.city }},
                                                    {{ store?.state }}
                                                </address>
                                                <br />
                                                <div style="
                                                display: flex;
                                                justify-content: space-between;
                                            ">
                                                    <span class="location-away">{{
                                                        store?.distance
                                                        | number : "1.2-2"
                                                        }}
                                                        km away</span>

                                                    <span class="get-direction"><a *ngIf="store.gmapLink" href="{{
                                                            store?.gmapLink
                                                        }}" class="view-store" target="_blank">{{
                                                            "store-listing.store-card.link-get-directions"
                                                            | translate
                                                            }}</a></span>
                                                </div>

                                                <a href="javascript:;" class="share-but" (click)="share(store)">{{
                                                    "store-listing.share"
                                                    | translate
                                                    }}</a><br />
                                                <span class="store-time open-icon float-left" *ngIf="store.timings">
                                                    <strong>
                                                        {{
                                                        "store-listing.store-card.business-hours"
                                                        | translate
                                                        }}
                                                        -
                                                        {{
                                                        utily.getTodayStoreTimings(
                                                        store?.timings
                                                        )
                                                        }}
                                                    </strong>
                                                </span>
                                                <br />
                                                <div class="view-section-call">
                                                    <span *ngIf="
                                                        appComp.discover
                                                            .value === 'stores'
                                                    " class="view-store-details"><a *ngIf="
                                                            store?.formattedAddress
                                                        " href="/{{
                                                            appComp.countryName
                                                        }}/{{
                                                            getLang()
                                                        }}/store/{{
                                                            store.formattedAddress
                                                        }}" href="javascript:;">{{
                                                            "store-listing.store-card.view-more-details"
                                                            | translate
                                                            }}</a>

                                                    </span>
                                                    <!-- <span class="view-store-details">
                                                        <a href="{{
                                                        store.buyOnlineLink
                                                    }}" class="demo-link" target="_blank" *ngIf="
                                                        store?.buyOnlineLink &&
                                                        appComp.discover
                                                            .value ===
                                                            'stores'
                                                    ">Buy Online</a>
                                                    </span> -->
                                                </div>
                                                <div class="btm-content">
                                                    <a *ngIf="
                                                            store?.maskingNum
                                                        " href="javascript:;" class="call-store-but" (click)="
                                                            showCallScreen(
                                                                store
                                                            )
                                                        ">{{
                                                        "store-listing.store-card.call-store"
                                                        | translate
                                                        }}</a>
                                                    <!-- <a *ngIf="
                                                            store?.phoneNumber &&
                                                            appComp.discover
                                                                .value ===
                                                                'service centers'
                                                        " href="javascript:;" class="call-store-but" (click)="
                                                            showCallScreen(
                                                                store
                                                            )
                                                        ">Call Service Center</a> -->
                                                    <div class="text-center pt-1">
                                                        <!-- <a *ngIf="
                                                                store.bookademo ==
                                                                true
                                                            " href="/bookademo?cid=in:other:9o8v9h"
                                                            class="demo-link">Book a Demo</a>
                                                        &nbsp;&nbsp; -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container>
                                            <a href="javascript:;" *ngIf="store.sellertype !== 'Reseller'" (click)="
                                                    viewMap(
                                                        $event,
                                                        'mobile',
                                                        store
                                                    )
                                                " class="view-button view-on-map">{{
                                                "store-listing.label-view-on-map"
                                                | translate
                                                }}</a>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <!-- <ng-container *ngIf="
                                        i === 4 &&
                                        appComp.discover.value === 'stores'
                                    ">
                                    <div #surveyBox1 class="card-big-bx survey-bg">
                                        <div class="survey-intro-screen survey-content">
                                            <h4 class="">
                                                How do you rate your experience
                                                of finding a nearby Lenovo
                                                store?
                                            </h4>
                                            <span class="d-flex justify-content-center">
                                                <img src="./assets/images/survey-img.png" width="200" height="155"
                                                    alt="" class="img-fluid" />
                                            </span>
                                            <div
                                                class="survey-number-bx survey-content d-flex justify-content-center pt-3">
                                                <div class="row">
                                                    <div class="col d-flex flex-column" style="min-width: 300px">

                                                        <div class="radio-bx">
                                                            <div class="desktop-rating-wrapper">
                                                                <app-emoji-scale (rateEvent)="
                                                                        initSurvey(
                                                                            $event
                                                                        )
                                                                    " [ratingLength]="
                                                                        currentItem
                                                                    " [ratingString]="
                                                                        ratingString
                                                                    "></app-emoji-scale>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container> -->
                            </div>
                            <div id="morestorelistholder">
                                <div id="mobstoreGallery" class="result-listing-bx"
                                    style="display: flex; flex-direction: row"></div>
                            </div>
                            <div *ngIf="
                                    storeListBeyondRadius &&
                                    storeListBeyondRadius.length > 0
                                " id="storelistBeyondRadiusholder">
                                <div id="mobstoreBeyondRadiusGallery" class="result-listing-bx"
                                    style="display: flex; flex-direction: row"></div>
                            </div>
                            <div class="text-center d-flex align-items-center justify-content-center"
                                style="min-width: 200px; background: #1f308a" (click)="getMoreStores(25)" *ngIf="
                                    this.appComp.loadMore == this.appComp.record
                                ">
                                <a href="javascript:;" style="color: white">Load More</a>
                            </div>
                        </div>
                    </div>
                    <div class="tab-cont tab-details tab2">
                        <div class="card-group-bx"></div>
                    </div>
                    <div class="tab-cont tab-details tab3">
                        <div class="card-group-bx"></div>
                    </div>
                </div>
            </div>
            <!-- call slide section start -->
            <div class="call-slide-section" id="callSlideSection">
                <a href="javascript:;" class="close-call-section" (click)="closeCallBook()"></a>
                <div class="call-section-content">
                    <div class="screen-1">
                        <span class="txt1">Would you like to talk to someone at
                            <strong>{{ storeName }}?</strong></span>
                        <a href="tel:{{ storeNumber }}" class="button-white">{{"store-listing.store-card.call-store" |
                            translate}}</a>
                        <span class="txt2 d-none">Would you like Ace Infocom to call you back?<br />
                            <a href="javascript:;" class="goto-screen2">Yes, call me back instead</a></span>
                    </div>
                    <div class="screen-2 call-screens">
                        <span class="txt3">Enter your mobile number</span>
                        <input type="number" value="" placeholder="Enter 10-digit number" class="mobile-num-input" />
                        <a href="javascript:;" class="button-blue goto-screen3">Submit</a>
                        <span class="txt2">Our policies do not allow spamming. <br />So be
                            assured.</span>
                    </div>
                    <div class="screen-3 call-screens">
                        <span class="thank-you-icon"></span>
                        <span class="txt4">Thank You!</span>
                        <span class="txt4">You shall recieve a call from our store in a few
                            minutes.</span>
                        <span class="txt2">Our policies do not allow spamming. <br />So be
                            assured.</span>
                    </div>
                </div>
            </div>
            <!-- call slide section end -->
            <!-- map section start -->
            <div #mapElement class="map-section" id="mapSection">
                <div id="map_canvas_mobile" style="position: absolute; width: 100%; height: 100%"></div>
                <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241505.2523226936!2d72.68476514995965!3d18.952648027803843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9dc81753c1b%3A0xe9ab9faa1ddbc947!2sLenovo%20Exclusive%20Store%20-%20Ace%20Infocom!5e0!3m2!1sen!2sin!4v1616914446433!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>-->
                <a href="javascript:;" class="view-button view-list-but" (click)="openViewList()">{{
                    "store-listing.label-view-on-list" | translate
                    }}</a>
            </div>
            <!-- map section end -->
            <!-- View Listing start -->
            <div class="view-listing-screen" id="viewListingScreen">
                <a (click)="closeViewListingScreen('all')" href="javascript:;"
                    class="close-listing-screen closeViewListingScreen"></a>
                <div class="card-big-bx" *ngIf="singleStore.length > 0">
                    <div class="white-bg-bx">
                        <div class="top-section">
                            <span class="store-time open-icon float-left">
                                {{
                                "store-listing.store-card.business-hours" | translate
                                }}
                                -

                                {{
                                utily.getTodayStoreTimings(
                                singleStore[0]?.timings
                                )
                                }}
                            </span>
                        </div>
                        <div class="btm-section">
                            <span class="store-name">{{ singleStore[0].displayName }}</span>
                            <a *ngIf="singleStore.length > 0" href="javascript:;" class="share-but float-right mb-3"
                                (click)="share(singleStore[0])">{{
                                "store-listing.share"
                                | translate
                                }}</a>

                            <div>
                                {{ singleStore[0].address}}
                                {{singleStore[0].city ? ", " + singleStore[0]?.city : ""}}
                                {{singleStore[0].state ? ", " + singleStore[0]?.state : "" }}
                            </div>

                            <div class="view-section-call">
                                <span *ngIf="
                                        appComp.discover
                                            .value === 'stores'
                                    " class="view-store-details"><a *ngIf="
                                        singleStore[0]?.formattedAddress
                                        " href="/{{
                                            appComp.countryName
                                        }}/{{
                                            getLang()
                                        }}/store/{{
                                            singleStore[0].formattedAddress
                                        }}" href="javascript:;">{{
                                        "store-listing.store-card.view-more-details"
                                        | translate
                                        }}</a>

                                </span>
                                <!-- <span class="view-store-details">
                                    <a href="{{
                                        singleStore[0].buyOnlineLink
                                    }}" class="demo-link" target="_blank" *ngIf="
                                    singleStore[0]?.buyOnlineLink &&
                                        appComp.discover
                                            .value ===
                                            'stores'
                                    ">Buy Online</a>
                                </span> -->
                            </div>
                            <div class="btm-content">
                                <div class="d-flex justify-content-around mt-3" *ngIf="singleStore[0]?.distance">
                                    <span class="location-away">{{
                                        singleStore[0]?.distance
                                        | number : "1.2-2"
                                        }}
                                        km away</span>
                                    <span *ngIf="singleStore[0]?.gmapLink" class="get-direction"><a href="{{
                                                singleStore[0]?.gmapLink
                                            }}" class="directions" target="_blank">{{
                                            "store-listing.store-card.link-get-directions"
                                            | translate
                                            }}</a></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- View Listing end -->
        </div>
    </div>
    <!-- store screen end -->
    <!-- survey start -->
    <div #surveyScreen class="survey-screen-bx" id="surveyScreen">
        <!-- <iframe src="./cx?s=150"></iframe> -->
        <a href="javascript:;" class="close-listing-screen closeSurveyScreen" (click)="closeSurveyScreen()"></a>
        <a href="/" class="logo-vertical">
            <img src="./assets/images/lenovo-logo-vert.webp" width="30" height="85" alt="" loading="lazy" />
        </a>
        <div class="survey-screen survey-second-screen h-100 pad-top survey-bg">
            <div class="container">
                <div class="survey-content pt-sm-0">
                    <div class="row justify-content-center">
                        <h4 style="font-weight: 300" class="text-center">
                            You've rated &nbsp;
                            <img style="vertical-align: middle" width="24px"
                                src="../../assets/images/emoji{{ rated }}.png" loading="lazy" />
                            &nbsp;
                            <span style="font-weight: 500">
                                {{ ratingString[rated - 1] }}
                            </span>
                            &nbsp;for finding a nearby Lenovo store
                        </h4>
                    </div>

                    <!-- <ul class="screen-number">
                <li>&nbsp;</li>
                <li class="active">&nbsp;</li>
                 <li>&nbsp;</li>
             </ul> -->
                    <div>
                        <h4 class="text-center">
                            We would like to understand the reasons behind your
                            rating.<br /><span>Select 1 or more reasons.</span>
                        </h4>
                    </div>
                    <div class="check-bx" *ngIf="surveyRatingQuestions.length > 0">
                        <ul class="clearfix">
                            <li *ngFor="
                                    let ques of surveyRatingQuestions;
                                    let i = index
                                ">
                                <div class="check-main">
                                    <input type="checkbox" id="check{{ i + 1 }}" (change)="captureResponse(ques.id)" />
                                    <label for="check{{ i + 1 }}">{{
                                        ques.name
                                        }}</label>
                                </div>
                            </li>
                            <!-- <li>
                      <div class="check-main">
                          <input type="checkbox" id="check2">
                          <label for="check2">I found the store closest to my location</label>
                      </div>
                      </li>
                      <li>
                      <div class="check-main">
                          <input type="checkbox" id="check3">
                          <label for="check3">The information provided helped me connect with the store</label>
                      </div>
                      </li>
                      <li>
                      <div class="check-main">
                          <input type="checkbox" id="check4">
                          <label for="check4">I found all the information I was looking for</label>
                      </div>
                      </li> -->
                        </ul>
                    </div>
                    <div class="survey-content pt-md-5 pt-sm-3">
                        <!-- <ul class="screen-number">
                  <li>&nbsp;</li>
                  <li>&nbsp;</li>
                  <li class="active">&nbsp;</li>
               </ul> -->
                        <div>
                            <h4 class="text-center">
                                Based on your experience of finding a nearby
                                store, how likely are you to recommend Lenovo to
                                your family, friends and peers?
                            </h4>
                        </div>
                        <div class="slider-wrapper d-flex flex-column mb-4">
                            <!-- <div  class="d-flex mb-2 nps-scale" style="justify-content: space-evenly;padding: 10px 0;">
                      <div>0</div>
                      <div>1</div>
                      <div>2</div>
                      <div>3</div>
                      <div>4</div>
                      <div>5</div>
                      <div>6</div>
                      <div>7</div>
                      <div>8</div>
                      <div>9</div>
                      <div>10</div>

                   </div> -->
                            <!-- <div id="range">
                     </div> -->
                            <!-- <ngx-star-rating [(ngModel)]="rating3"  [id]="'rating'"></ngx-star-rating> -->
                            <div>
                                <div class="desktop-nps-rating" *ngIf="currentItem1 > 0">
                                    <app-emoji-scale (rateEvent)="onRate1($event)" [ratingLength]="currentItem1"
                                        [ratingString]="ratingString1"></app-emoji-scale>

                                    <!-- <star-rating #star3  [value]="-1" [totalstars]="11" [checkedcolor]="checkedcolor" [uncheckedcolor]="uncheckedcolor"  [readonly]="false" [size]="sizeRange"  (rate)="onRate($event)"></star-rating> -->
                                </div>
                            </div>
                            <!-- <div  class="d-flex justify-content-between rating-top fonsize mt-2">
                      <div class="rating-word1" > <span>Not at all</span> <span> recommended</span> </div>

                      <div class="rating-words"> <span>Highly</span><span> recommended</span> </div>
                   </div> -->
                            <!-- <div class="d-flex flex-column align-items-center scale-rating  mt-4">
                       <p class="sub-txt">
                           Please rate on a scale of 0 - 10 where 0 is not at all recommended and 10 is highly recommended
                       </p>
                   </div> -->
                        </div>
                        <!-- <div class="captcha text-center d-flex justify-content-center">
                  <div id="html_element">
                      <re-captcha #reCaptcha type="image" (resolved)="resolved($event)"
                          [siteKey]="appComp.captchaKey"></re-captcha>
                  </div>
                  </div> -->
                    </div>
                    <!-- <button
          [ngClass]="'button-white nextButton'"
          (click)="showSurveyNext(3,$event)">Submit</button> -->
                    <div class="row justify-content-center">
                        <a href="javascript:;" class="button-gradient1 cta-buy-post"
                            (click)="showSurveyNext(3, $event)">Submit</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="survey-screen survey-third-screen h-100 pad-top survey-bg">
       <div class="container">

       </div>
       <button
       [ngClass]="'button-white submitButton'"
       (click)="showSurveyNext(3,$event)">Submit</button>
    </div> -->

        <div class="survey-screen thank-you-screen h-100 survey-bg">
            <div class="container h-100">
                <div class="thank-you-cont survey-content text-center">
                    <span class="thank-you-img">
                        <img src="./assets/images/thank-you-img.png" width="100%" height="auto" alt="" loading="lazy" />
                    </span>
                    <p>Thank you!</p>
                    <p>
                        Your feedback is valuable to us and we appreciate you
                        taking the time out to let us know!
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- survey end -->
</div>
<ng-template #surveyModal let-modal>
    <span (click)="dismissSurvey()" style="
            cursor: pointer;
            position: absolute;
            right: -10px;
            background: rgb(27, 43, 129);
            border-radius: 50%;
            width: 30px;
            height: 30px;
            top: -10px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-weight: 800;
        ">X</span>
    <app-popup-survey [_for]="isDesktop ? 'desktop' : 'mobile'" (selectedOption)="initSurveyFromPopup($event)">
    </app-popup-survey>
</ng-template>
<ng-template #studentOfferModal let-modal>
    <span (click)="dismissStudentOffer()" style="
            cursor: pointer;
            position: absolute;
            right: -10px;
            background: rgb(27, 43, 129);
            border-radius: 50%;
            width: 30px;
            height: 30px;
            top: -10px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-weight: 800;
        ">X</span>
    <div id="studentOffer" style="width: 300px; margin: 0 auto">
        <button type="button" class="close btn btn-default close-btn close-btn-1" id="overly_cls" data-dismiss="modal"
            aria-hidden="true" onclick="showofferbtn()"></button>
        <h3 class="text-center" style="font-size: 1.2rem">Student Offer</h3>
        <div class="" style="margin: 0px !important">
            <div class="student-input">
                <form class="main-form clearfix" [formGroup]="studentOfferForm">
                    <div class="white-container mt-4" style="padding: 0; height: 35px">
                        <input [(ngModel)]="offerCode" formControlName="offerCode" type="text" id="offerCode"
                            class="textbox text-center" placeholder="Enter Offer Code" />
                    </div>
                </form>
                <p class="px-2 text-center" *ngIf="
                        studentOfferForm.controls.offerCode.touched &&
                        studentOfferForm.controls.offerCode.errors
                    " style="margin: 5px; color: red; font-size: 0.8rem">
                    {{ errorMsg }}
                </p>
            </div>
            <div id="errorMsg" class="text-center" style="display: none">
                <p id="error"></p>
            </div>
            <div class="text-center my-2" style="display: flex; justify-content: center">
                <button id="offerVal" (click)="submitOfferCode()" class="button-gradient disabled">
                    Validate
                </button>
            </div>
            <div class="text-center">
                <p style="
                        color: #1b2b81;
                        font-size: 0.8rem;
                        margin: 15px 0px 20px 0px;
                    ">
                    Please enter the offer code received from the store or
                    Lenovo.com
                </p>
            </div>
        </div>
    </div>
</ng-template>

<div id="countries">
    <div mat-dialog-title style="
            float: right;
            font-weight: 700;
            cursor: pointer;
            color: white;
            font-size: 20px;
        " (click)="close()">
        X
    </div>
    <div class="popular-cities-bx" *ngIf="appComp.popCities && !this.appComp.countryListShow">
        <p>
            {{ "store-listing.label-popular-cities" | translate }} <br /><a href="javascript:;"
                *ngFor="let city of appComp.popCities" (click)="getStoresByCity(city.lat, city.lng, city.city)">{{
                city.city }}</a>
        </p>
    </div>
    <div class="popular-cities-bx" *ngIf="this.appComp.countryListShow">
        <p style="font-weight: 800">
            {{ "store-listing.tab-title-country-region" | translate }} <br /><a class="d-flex align-items-center"
                href="javascript:;" *ngFor="let country of countriesList"
                (click)="getCountrySelected(country.code, country.name)">
                {{ country.name }}</a>
        </p>
    </div>
</div>
