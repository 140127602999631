import { AppComponent } from '../../app.component';
import {  Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Route, Router } from '@angular/router';
import { ApacService } from 'src/app/services/apac.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;
import {
    BreakpointObserver,
    Breakpoints,
    BreakpointState,
} from '@angular/cdk/layout';
import { HomeComponent } from 'src/app/home/home.component';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
    countries: any;
    selectedLang: any = '';
    selectedCountry: string = '';
    countryLists: any[] = [];

    showLangSelection: boolean = false;

    constructor(
        public appComp: AppComponent,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private apac: ApacService,
        private utility: UtilitiesService,
        public translate: TranslateService,
        private breakpointObserver: BreakpointObserver,
        public home: HomeComponent
    ) { }
    mob = true;

    ngOnInit(): void {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
              if (event.navigationTrigger === 'popstate' ) {
                this.appComp.language = 'en';
              }
            }
          });
        this.breakpointObserver
            .observe(['(max-width: 991px)'])
            .subscribe((state: BreakpointState) => {
                if (state.matches) {
                    this.mob = true;
                    console.log('Viewport width is 500px or greater!');
                } else {
                    this.mob = false;
                    console.log('Viewport width is less than 500px!');
                }
            });

        this.apac.languagesObservable$.subscribe(conAndLang => {
            if (Array.isArray(conAndLang) && conAndLang.length > 0) {
                this.appComp.languages = conAndLang;
                if (this.countryLists.length == 0) this.countryLists = conAndLang;
                if (this.home.countriesList == 0) this.home.countriesList = conAndLang;
                this.getCountyAndLang(this.appComp.countryName);
            }
        })
    }

    getCountyAndLang(en: any) {
        this.appComp.languages.forEach((country) => {
            if (country.code == en) {
                this.countries = country;
                this.appComp.lngs = country.langList;
                if (this.appComp.language != "en") {
                    this.appComp.language = country.langList.filter((c: any) => c.code == this.appComp.language)[0].code;
                }


                // this.appComp.language = country.langList[0].code;
                // let i = country.langList.findIndex(
                //     (c: any) => c.code == this.appComp.localeId.split('-')[0]
                // );
                // if (i > -1) {
                //     this.appComp.language = country.langList[i].code;
                // } else {
                //     this.appComp.language = country.langList[0].code;
                // }
                this.appComp.localeId =
                    this.appComp.language + '-' + this.appComp.countryName;

            }
        });
    }

    nav2ServiceCenter() {
        this.router.navigate(['service-centers']);
    }
    open() {
        console.log('test');
        $('.main-nav').addClass('menu-open');
    }
    close() {
        $('.main-nav').removeClass('menu-open');
    }



    go(_to: string) {

    if (_to == 'home' || _to === 'stores') {
        if(window.location.pathname === '/select-country'){
            window.location.href = `/`;
            return;
       }
        this.router.navigate(["/" + this.appComp.countryName + "/" + this.appComp.language])
        } else if (_to === 'warranty') {
            window.open(`https://pcsupport.lenovo.com/` + this.appComp.countryName + `/en/warrantylookup`, '_blank');
        } else if (_to === 'website') {
            const country = this.appComp.countryName.toLowerCase();
            const lenovoUrl = (country === 'la' || country === 'kh')
                ? `https://lenovo.com`
                : `https://lenovo.com/${country}`;

            window.open(lenovoUrl, '_blank');
        }

    }
    getTollNumber(): boolean {
        return (
            this.router.url === "/_redeem" ||
            this.router.url === "/terms" ||
            this.router.url === "/faq"
        );
    }


    selectChange(ev: any) {
        if (window.innerWidth < 991) {
            this.appComp.countryListShow = true;
        }
        this.appComp.isLoad = true;
        this.appComp.countrySub.next(ev.target.value);
        this.appComp.countryName = ev.target.value;
        let c = this.appComp.languages.find((c) => c.code === ev.target.value);
        this.appComp.lngs = c.langList;
        localStorage.removeItem('lat');
        localStorage.removeItem('lng');
        localStorage.removeItem('latitude');
        localStorage.removeItem('longitude');
        this.appComp.locationOn.next(false);
        this.appComp.SetLanguageAfterCountryDropdown(
            this.appComp.countryName,
            null
        );
        this.appComp.selected = false;
    }

    selectLangsChange(ev: any) {
        localStorage.removeItem('lat');
        localStorage.removeItem('lng');
        localStorage.removeItem('latitude');
        localStorage.removeItem('longitude');
        this.appComp.isLoad = true;
        this.appComp.localeSub.next(ev.target.value);

        this.appComp.language = ev.target.value;
        this.appComp.localeId =
            ev.target.value + '-' + this.appComp.countryName;

        if (window.innerWidth > 991) {
            if (this.appComp.locationOn.value == true) {
                this.appComp.locationOn.next(true);
            } else {
                this.appComp.locationOn.next(false);
            }
        }
        this.appComp.selected = true;


        this.appComp.SetLanguageAfterCountryDropdown(
            this.appComp.countryName,
            ev.target.value
        );
        // this.router.navigate([
        //   this.appComp.countryName + "/" +  this.appComp.localeId.split("-")[0]
        // ]);
    }

    showCountries() {

        this.appComp.isLoad = true;
        if (this.home.countriesList && this.home.countriesList.length > 0) {
            if (window.innerWidth < 991) {
                this.appComp.countryListShow = true;
                $('#countries').show().animate({ left: '0' }, 300);
                this.appComp.isLoad = false;
            }
        } else {
            this.setLanguageAfterCountryDrop()

            setTimeout(() => {
                if (window.innerWidth < 991) {
                    this.appComp.countryListShow = true;
                    $('#countries').show().animate({ left: '0' }, 300);
                    this.appComp.isLoad = false;
                }
            }, 600)
        }


    }

    setLanguageAfterCountryDrop() {
        this.apac.languagesObservable$.subscribe(conAndLang => {
            if (Array.isArray(conAndLang)) {
                this.home.countriesList = conAndLang;
                this.appComp.languages = conAndLang;
            }
        })
        // this.apac.supportedcountrylang().subscribe(scl => {
        //     this.home.countriesList = scl.sort( (a: object | any, b: object | any) => a.name.localeCompare(b.name));
        //     this.appComp.languages = scl.sort( (a: object | any, b: object | any) => a.name.localeCompare(b.name));
        //   })
    }

}
