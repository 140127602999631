<div class="carousel-container" [style.height]="height">
  <div class="main-slider position-relative">
    <button (click)="prevMainBanner()" class="carousel-button arrow left-arrow" [disabled]="currentIndex === 0">
      <img src="/assets/images/leftArrow.svg" alt="Next" width="28" height="28" class="arrowcustom">
    </button>
    <div class="main-banner">
      <img *ngIf="canShowImage(currentBanner)" [src]="currentBanner.path" [alt]="currentBanner.alt"
        style="width: 100%;">
      <ng-container *ngIf="canShowVideo(currentBanner)">
        <div class="video-wrapper">
          <iframe #videoFrame class="iframe" [src]="transform(currentBanner.path)" title="YouTube video player" allow="autoplay; fullscreen"
            scrolling="no" frameborder="0" referrerpolicy="strict-origin-when-cross-origin">
          </iframe>
          <div class="newDivOverlay" (click)="playVideo()"></div>
        </div>
      </ng-container>
    </div>
    <button (click)="nextMainBanner()" class="arrow right-arrow carousel-button" [disabled]="currentIndex === bannerImages.length - 1">
      <img src="/assets/images/rightArrow.svg" alt="Next" width="28" height="28" class="arrowcustom">
    </button>
  </div>

  <div class="thumbnail-slider container d-flex align-items-center justify-content-center">
    <button (click)="prevThumbnails()" class="arrow thumbnail-arrow" *ngIf="visibleThumbnails.length >= thumbnailsToShow"
      [disabled]="currentThumbnailIndex === 0">
      &#10094;
      <!-- <img src="/assets/images/leftArrow.svg" alt="Next" width="28" height="28"> -->
    </button>
    <div class="thumbnail-carousel">
      <div *ngFor="let item of visibleThumbnails; let i = index" class="thumbnail-item"
        [ngClass]="{'selected-thumbnail': currentIndex === currentThumbnailIndex + i}">
        <img *ngIf="item.type == 'video'" [src]="item.thumbnail" [alt]="item.alt" width="100%"
          height="100%" class="thumbnail-image" (click)="onThumbnailClick(i)">
        <img *ngIf="item.type == 'img'" [src]="item.path" [alt]="item.alt" class="thumbnail-image"
          (click)="onThumbnailClick(i)">
      </div>
    </div>
    <button (click)="nextThumbnails()" class="arrow thumbnail-arrow" *ngIf="visibleThumbnails.length >= thumbnailsToShow"
      [disabled]="currentThumbnailIndex + thumbnailsToShow >= bannerImages.length">
      &#10095;
      <!-- <img src="/assets/images/rightArrow.svg" alt="Next" width="24" height="24"> -->
    </button>
  </div>
</div>