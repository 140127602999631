import { AdminBannerImages } from './../models/banner';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharingService } from '../services/sharing.service';
import { Result } from './../models/search';
import { SurveyResponse, City } from './../models/survey';
import { ApiService } from '../services/api.service';
import { BehaviorSubject, forkJoin, from, ReplaySubject } from 'rxjs';
import { Store, StoresRequest } from './../models/store';
import { AppComponent } from './../app.component';
import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { Location } from './../models/location';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { StarRatingComponent } from 'ng-starrating';
import { ApacService } from '../services/apac.service';
import { UtilitiesService } from '../services/utilities.service';
import { ApacOffersComponent } from '../widgets/apac-offers/apac-offers.component';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { info } from 'console';
import { ThisReceiver } from '@angular/compiler';

declare var $: any;
declare var grecaptcha: any;
declare var noUiSlider: any;
declare var google: any;


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('preciseLocationIndicator') preciseLocationIndicator!: ElementRef;
  @ViewChild('foundStores1') foundStores1!: ElementRef;
  @ViewChild('foundStores2') foundStores2!: ElementRef;
  @ViewChild('foundServiceCenters') foundServiceCenters!: ElementRef;
  @ViewChild('discoverStores') discoverStores!: ElementRef;
  @ViewChild('discoverServiceCenters') discoverServiceCenters!: ElementRef;
  @ViewChild('tabContServiceCenters') tabContServiceCenters!: ElementRef;
  @ViewChild('tabContStores') tabContStores!: ElementRef;
  @ViewChild('offerGallery') offerGallery!: ElementRef;
  @ViewChild('bxWrapper') bxWrapper!: ElementRef;
  @ViewChild('mapElement') mapElement!: ElementRef;
  @ViewChild('searchLocation') searchLocation!: ElementRef;
  @ViewChild('mobilePlacesSearchInput') mobilePlacesSearchInput!: ElementRef;
  @ViewChild('mobilePlacesSearch') mobilePlacesSearch!: ElementRef;
  @ViewChild('mapResultText') mapResultText!: ElementRef;
  @ViewChild('surveyBox2') surveyBox2?: ElementRef;
  @ViewChild('surveyBox1') surveyBox1?: ElementRef;
  @ViewChild('whatsLatest') whatsLatest?: ApacOffersComponent;
  @ViewChild('surveyModal') surveyModal?: ElementRef;
  @ViewChild('storeTab') storeTab?: ElementRef;
  @ViewChild('serviceTab') serviceTab?: ElementRef;
  @ViewChild('star1') star1?: StarRatingComponent;
  @ViewChild('star2') star2?: StarRatingComponent;
  @ViewChild('star3') star3?: StarRatingComponent;
  foundStore: string = "Finding stores..."

  @ViewChild("studentOfferModal", { static: true, })
  studentOfferModal?: TemplateRef<any>;

  @ViewChild("PsuOfferModal", { static: true, })
  PsuOfferModal?: TemplateRef<any>;

  stores?: any;
  morestores?: Store[];
  offers: string[] = [];
  storesCount = new BehaviorSubject<number>(0);
  surveyRatingQuestions: City[] = [];
  fetchingQuestions = false;
  captcha!: string;
  questions1!: string;
  questions2!: string;
  checkIds!: string;
  checkIdsArr: number[] = [];
  slider: any;
  places: Result[] = [];
  searchString?: string = '';
  google: any;
  mapOpened: boolean = false;
  singleStore: any[] = [];
  storeListBeyondRadius?: any[];
  storeNumWithinRadius?: number;
  storeNumBeyondRadius?: number;
  locationOn = new BehaviorSubject<boolean>(false);
  storeNum?: number;
  storeStartCount: number = 0;
  placeIdRequest?: boolean = false;
  storeStartNum: number = 25;
  recNum: number = 25;
  tmpMsg: boolean = false;
  endTime: boolean = false;
  storetemplate: string = '';
  storeMobTemplate: string = '';
  surveyCard: string = 'hide';
  showSurveyCard: boolean = false;
  captchaElem: any;
  captchaFormGroup: FormGroup = this.formBuilder.group({
    recaptcha: ['', Validators.required]
  });
  surveyModalRef: any;
  surveyDisplayed: boolean = false;
  studentOfferParam?: string | null;
  studentOfferModalRef: any;
  PsuOfferModalRef: any;

  storeName?: string = '';
  storeNumber?: string = '';
  currentSlide = 0;
  bannerimages: any[] = [];
  banners: any[] = [];
  isDesktop: boolean = true;
  offersHeight: number = 400;
  assistanceHeight: number = 257;
  assistanceWidth: number = 550;
  surveyTimer: any;
  showStudentOfferPopup = new BehaviorSubject<boolean>(false);
  showPsuOfferPopup = new BehaviorSubject<boolean>(false);
  studentOfferForm: any;
  offerCode?: string;
  psuofferCode?: string;
  activateRoute = new BehaviorSubject<string>('stores');
  gotServicePath: boolean = false;
  userInteractionsArray: any[] = [];
  initialStar = 0;
  redirectionUrl:any ="";

  totalstar = 10;
  starratingValue: number = -1;

  ratingString: string[] = ["Very Poor", "Poor", "Average", "Good", "Very Good"];
  ratingString1: string[] = ["Very Unlikely", "", "", "Very likely"];
  currentItem: number = 5;
  currentItem1: number = 11;

  countriesList = [] as any;
  con: any = [];



  constructor(
    public appComp: AppComponent,
    private renderer: Renderer2,
    private apiService: ApiService,
    private sharingService: SharingService,
    private formBuilder: FormBuilder,
    public modalService: NgbModal,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private router: Router,
    private ele: ElementRef,
    private apac: ApacService,
    public utily: UtilitiesService,
    @Inject(DOCUMENT) document: Document,
    public translate: TranslateService
  ) {
    // this.appComp.countryListShow = true;
    this.studentOfferForm = this.formBuilder.group({
      offerCode: ['', Validators.required]
    });

    this.studentOfferForm = this.formBuilder.group({
      psuofferCode: ['', Validators.required]
    });

    this.router.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {

        if (event.url.includes('service-centers') && appComp.discover.value === 'stores') {
          // this.toggleDiscovery(null);
        }
      }

    });
    this.apac.getLocationByIp().subscribe(countyDetail => {
      this.appComp.currentCountry = countyDetail.countryCode.toLowerCase();
    })
    this.initializeApac()

  }
  initializeApac() {
    if (this.router.url === "/select-country") {
      this.appComp.isLoad = false;
      this.appComp.countryListShow = true;
      localStorage.removeItem("lat");
      localStorage.removeItem("lng");
      this.countriesList = this.appComp.languages;
      this.apac.languagesObservable$.subscribe(conAndLang => {
        this.appComp.languages = conAndLang;
        this.countriesList = conAndLang;
      })
      if (this.appComp.countryListShow) {
        this.translate.setDefaultLang('en');
        this.translate.use('en');
      }
    } else {
      this.appComp.countryListShow = false;
      this.route.params.subscribe(s => {
        if ((s["con"] && s["lng"]) && (s["formattedAddress"] == undefined)) {
          this.appComp.isLoad = true;
          // this.appComp.popCities = null;
          let lat = localStorage.getItem("lat");
          let lng = localStorage.getItem("lng");
          if ((lng != undefined && lng.length > 0) && (lat != undefined && lat.length > 0)) {
            this.appComp.currentLocationName = this.appComp.currentLocationName ? this.appComp.currentLocationName : localStorage.getItem("currentLocationName")?.toString();
            this.appComp.countryName = s["con"]
            this.appComp.localeId = s["lng"] + "-" + this.appComp.countryName;
            this.appComp.language = s["lng"];
            this.appComp.setNgxTranslation();
            this.appComp.locationChecking(lat, lng, true);
          } else {
            localStorage.removeItem("lat");
            localStorage.removeItem("lng");
            this.appComp.countryName = s["con"]
            this.apac.countryNameSubject.next(s["con"]);
            this.appComp.getHomeLatAnLang(s["con"]);
          }

          setTimeout(() => {
            if (this.appComp.cityPopForUnSupported) {
              if (!this.appComp.selected) this.showCountries();
            }
          }, 500)



        } else {
          if (s["formattedAddress"] == undefined) {
            this.appComp.setCountryAndLang();
          }else{
            this.appComp.language = s["lng"] ? s["lng"] : this.appComp.language;
            this.appComp.countryName = s["con"] ? s["con"] : this.appComp.language;
          }
        }
      });
    }
    if (this.appComp.languages.length > 0) {
      this.countriesList = this.appComp.languages.sort((a, b) => a.name.localeCompare(b.name));
      this.appComp.languages = this.appComp.languages.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  close() {
    this.appComp.countryListShow = false;
    if (window.innerWidth < 991) {
      $('#countries').hide();
    }

  }

  getCurrentUrl() {
    return this.appComp.redirectionUrl;
  }

  getCountrySelected(code: string, name: string) {

    this.appComp.cityPopForUnSupported = true;
    localStorage.removeItem('lat');
    localStorage.removeItem('lng');
    this.appComp.languages.forEach((country) => {
      if (country.code == code) {
        this.appComp.lngs = country.langList;
        this.appComp.language = country.langList[0].code;
        let i = country.langList.findIndex(
          (c: any) => c.code == this.appComp.localeId.split('-')[0]
        );
        if (i > -1) {
          this.appComp.language = country.langList[i].code;
        } else {
          this.appComp.language = country.langList[0].code;
        }

      }
    });
    this.appComp.SetLanguageAfterCountryDropdown(code, null);
    this.appComp.countryListShow = false;
    this.appComp.languageShowList = true;
    this.selectSellerTypeOnload("", "Exclusive", 0);
    this.appComp.langAndcountryChangeSubjets();
  }


  ngOnDestroy() {
    clearInterval(this.surveyTimer);

  }

  onRate($event: { oldValue: number, newValue: number, starRating: StarRatingComponent }) {
    this.starratingValue = $event.newValue - 1;

  }
  onRate1($event: any) {
    this.ratingString1 = ["Very Unlikely", "", "", "Very likely"];
    this.starratingValue = $event - 1;
    let dataLayer = (<any>window)['dataLayer'];
    dataLayer.push({ 'event': this.appComp.surveyDisplayMode + '-store' + '-question-2-' + this.starratingValue });
  }

  theKingIsBlackPageCallback(n: any) {
    return {
      type: 'image',
      src: './assets/books/image/theKingIsBlack/' + (n + 1) + '.jpg',
      interactive: false
    };
  }

  ngOnInit(): void {

    if ($(window).width() <= 767) {
      this.isDesktop = false;
      this.offersHeight = ($(window).width() / (1284 / 896)) - 12;
      const calH = ($(window).width() / (1500 / 700)) - 30;
      this.assistanceHeight = calH < 156 ? 156 : calH;
      this.assistanceWidth = (1500 / 700) * this.assistanceHeight;
    }




    if ($(window).width() <= 490) {

      this.sizeRange = "22px";
    }
    this.studentOfferParam = this.route.snapshot.queryParamMap.get('src');
    if (this.studentOfferParam) {
      this.showStudentOfferPopup.next(true);
    }

    this.studentOfferParam = this.route.snapshot.queryParamMap.get('psu');
    if (this.studentOfferParam) {
      this.showPsuOfferPopup.next(true);
    }

    window.addEventListener("resize", () => {
      this.contentTopPad()
    }, false);


  }


  detectUserInteraction() {
    ["click", "mousemove", "mouseover", "mousemove", "touchmove", "focus"].forEach((eventName) => {
      let interaction = window.addEventListener(eventName, () => {
        if (!this.appComp.surveyTriggered) {
          this.surveyTimer = setInterval(() => {
            const cxSurvey = this.cookieService.get("cxSurvey");
            if (!this.surveyModalRef && this.appComp.discover.value === 'stores' && !cxSurvey) {
              this.surveyDisplayed = true;
              this.surveyModalRef = this.modalService.open(this.surveyModal, { centered: true });
              let dt = new Date();
              dt.setHours(dt.getHours() + 4);
              this.cookieService.put("cxSurvey", "cxSurvey", { expires: dt })
            }
          }, 60000);
          this.appComp.surveyTriggered = true;
        }


        if (this.userInteractionsArray && this.userInteractionsArray.length > 0) {
          for (let interaction of this.userInteractionsArray) {

          }
        }
      });
      this.userInteractionsArray.push(interaction);
    });
  }


  ngAfterViewInit() {
    this.selectSellerTypeOnload("", this.appComp.sellertype, this.appComp.index)
    $(document).ready(() => {
      var elemts = $(".rating_box")
    })

    const Button = document.querySelector('#starratingId');

    this.showStudentOfferPopup.subscribe((val) => {
      if (val) {
        this.studentOfferModalRef = this.modalService.open(this.studentOfferModal, { centered: true, backdrop: 'static', windowClass: 'student-offer' });
      }
    });

    this.showPsuOfferPopup.subscribe((val) => {
      if (val) {
        this.PsuOfferModalRef = this.modalService.open(this.PsuOfferModal, { centered: true, backdrop: 'static', windowClass: 'student-offer' });
      }
    });
    this.contentTopPad();

    $(document).click((ev: any) => {
      const targetName = $(ev.target).attr('name');

      if (targetName !== undefined && targetName.indexOf("share_info") > -1) {
        const id = targetName.substring(11);
        const idx = this.stores?.findIndex((s: any) => s.id === Number(id));
        if (this.stores && idx !== undefined && idx > -1) {
          this.share(this.stores[idx]);
        }
      }

      if ($(ev.target).parent().attr('id') && $(ev.target).parent().attr('id').includes('offer_thumb_id_')) {
        const varId = Number($(ev.target).parent().attr('id').replace("offer_thumb_id_", ""));
        const currSlide = Number(this.whatsLatest?.getCurrentSlide());
        const diff = varId - currSlide;

        for (let i = 0; i < Math.abs(diff); i++) {
          diff > 0 ? this.whatsLatest?.nextSlide() : this.whatsLatest?.prevSlide();
        }
      }
    });


    //ng oninit code
    this.studentOfferParam = this.route.snapshot.queryParamMap.get('src');
    if (this.studentOfferParam) {
      this.showStudentOfferPopup.next(true);
    }

    //ng oninit code
    this.studentOfferParam = this.route.snapshot.queryParamMap.get('psu');
    if (this.studentOfferParam) {
      this.showPsuOfferPopup.next(true);
    }


    if ($(window).width() <= 767) {
      this.isDesktop = false;
      this.offersHeight = ($(window).width() / (1284 / 896)) - 12;
      this.assistanceHeight = ($(window).width() / (1500 / 700)) - 12;
    }

    this.banners = [
      {
        "src": "https://lenovo-apac.s3.ap-south-1.amazonaws.com/offer-banners/4329.jpg",
        "type": "img"
      },
      {
        "src": "https://lenovo-apac.s3.ap-south-1.amazonaws.com/offer-banners/Legion%205i%20Pro_1284x896_300%20Dpi.png",
        "type": "img"
      },
      {
        "src": "https://www.youtube.com/embed/GNi6dK7QP3I?si=hOXynzBkapdTtwTS",
        "type": "video"
      }
    ]

    this.utily.banners.subscribe(val => {
         console.log(val);
      this.banners = val;


    })

    this.appComp.locationOn.subscribe((val) => {
      if (val === true) {
        this.getStoreOnLocation();
      }
    });



    window.addEventListener("resize", () => {
      this.contentTopPad()
    }, false);

    setInterval(() => {
      const w = $('.desktop-rating-wrapper > star-rating');

      if (w && w.length > 0) {
        for (let i = 0; i < w.length; i++) {
          $(w[i].shadowRoot).find('div').css('display', 'flex').css('justify-content', 'space-evenly').css('padding', '10px 0');
        }
      }

    }, 1000);

    setInterval(() => {
      const n = $('.desktop-nps-rating > star-rating');
      if (n && n.length > 0) {
        for (let i = 0; i < n.length; i++) {
          $(n[i].shadowRoot).find('div').css('display', 'flex').css('justify-content', 'space-evenly').css('padding', '10px 0');
        }
      }

    }, 1000);
  }


  dismissSurvey() {
    this.surveyModalRef.dismiss();
    clearInterval(this.surveyTimer);
    this.surveyDisplayed = false;
    this.initialStar = 0;
  }

  dismissStudentOffer() {
    this.studentOfferModalRef.dismiss();
  }

  dismisspsuOffer() {
    // this.PsuOfferModalRef.close();
    this.modalService.dismissAll();
  }

  getStoreOnLocation() {
    this.storesCount.next(0);
    this.appComp.apacStoresRes.subscribe((val: any) => {
      this.stores = val;
      this.appComp.apacStoreWithKm.subscribe((km: any) => {
        this.storeNumWithinRadius = km[0].store_within_radius;
        this.storeNumBeyondRadius = km[0].store_beyond_radius;
        this.foundStore = this.translate.instant('store-listing.label-found') + " " + this.storeNumWithinRadius + " " + this.translate.instant('store-listing.label-store-near-you') + '<br>(' + this.storeNumBeyondRadius + " " + this.translate.instant('store-listing.label-stores-beyond') + ' 25Km)'
      })
      if (val.adminbannerimages) {
        this.offers = val.adminbannerimages;
        this.setOfferSectionWidth()
      }
    })
  }



  getMoreStores(storeReq: number) {
    this.appComp.isLoad = true;
    this.appComp.page = this.appComp.page + 1;
    let request = null;
    this.storetemplate = '';
    this.storeMobTemplate = '';
    if (this.placeIdRequest == true) {
      request = new StoresRequest(0, 0, this.appComp.discover.value === 'stores' ? 2 : 4, storeReq, this.storeStartNum);
      request.lat = undefined;
      request.lng = undefined;
      request.placeId = this.appComp.placeId;
    } else {
      request = new StoresRequest(this.appComp.currentLocation.lat, this.appComp.currentLocation.lng, this.appComp.discover.value === 'stores' ? 2 : 4, storeReq, this.storeStartNum);
    }
    let sellertype = this.appComp.sellertype;
    if (this.appComp.discover.value === 'stores') {
      this.appComp.isLoad = true;

      this.apac.getStoreDetails(this.appComp.tenantId, request.lat, request.lng, this.appComp.record, this.appComp.distance, "s", this.appComp.page,
        this.appComp.countryName, this.appComp.localeId.split("-")[0], sellertype,
        this.appComp.currentLocationName).subscribe((val) => {
          this.appComp.isLoad = false;
          this.morestores = val;
          this.appComp.loadMore = val.length;
          this.getmorestoretemp();
        }, err => {
          this.appComp.isLoad = false;
        })
    } else {
      this.apac.getStoreDetails(this.appComp.tenantId, request.lat, request.lng, this.appComp.distance, this.appComp.record, "sc", this.appComp.page,
        this.appComp.countryName, this.appComp.localeId.split("-")[0], sellertype, this.appComp.currentLocationName).subscribe((val) => {
          this.morestores = val;
          this.getmorestoretemp();
        })
    }
  }




  getmorestoretemp() {
    if (this.morestores && this.morestores?.length) {
      this.storeStartNum = this.storeStartNum + this.morestores?.length;

      for (let store of this.morestores) {
        if (store.phoneNumber) {
          store.phoneNumber = store.phoneNumber;
        }
        if (this.appComp.discover.value === 'stores') {
          store.gmapLink = store.gmapLink;
        }
        if (this.appComp.discover.value === 'service centers') {
          store.gmapLink = 'https://www.google.com/maps/place/' + store.lat + ',' + store.lng
        }

        let storetemplate = this.getTemplate(store);
        let storemobtemplate = this.getStoreTempForMobile(store);

        $('#storeGallery').append($(storetemplate));
        $('#mobstoreGallery').append($(storemobtemplate));

        if (store && store.storeId) {
          document.getElementById('share_' + store.storeId)?.addEventListener('click', (e: MouseEvent) => {
            this.share(store);
            return false;
          })
        }
        if (store && store.storeId) {
          document.getElementById('sharemob_' + store.storeId)?.addEventListener('click', (e: MouseEvent) => {
            this.share(store);
            return false;
          })
        }
        if (store && store.storeId) {
          document.getElementById('callNow_' + store.storeId)?.addEventListener('click', (e: MouseEvent) => {
            this.showCallScreen(store);
            return false;
          })
        }
        if (store && store.storeId) {
          document.getElementById('callNowMob_' + store.storeId)?.addEventListener('click', (e: MouseEvent) => {
            this.showCallScreen(store);
            return false;
          })
        }
        if (store && store.storeId) {
          document.getElementById('viewMap_' + store.storeId)?.addEventListener('click', (e: MouseEvent) => {
            this.viewMap(e, 'mobile', store);
            return false;
          })
        }
      }
    }
  }

  getStoresBeyondRadius(storeReq: number, storeStartNum?: number) {
    let request = null;
    this.storetemplate = '';
    this.storeMobTemplate = '';
    if (this.placeIdRequest == true) {
      request = new StoresRequest(0, 0, this.appComp.discover.value === 'stores' ? 2 : 4, storeReq, storeStartNum);
      request.lat = undefined;
      request.lng = undefined;
      request.placeId = this.appComp.placeId;
    } else {
      request = new StoresRequest(this.appComp.currentLocation.lat, this.appComp.currentLocation.lng, this.appComp.discover.value === 'stores' ? 2 : 4, storeReq, storeStartNum);
    }

    if (this.appComp.discover.value === 'stores') {
      this.apiService.getStoresBeyondradius(request).then(result => {
        if (result.status === 'SUCCESS') {

          this.storeListBeyondRadius = result.listBeyondRadius;
          this.getStores();
        }
      });
    } else {
      this.apiService.getServiceCentersBeyondradius(request).then(result => {
        if (result.status === 'SUCCESS') {
          this.storeListBeyondRadius = result.listBeyondRadius;
          this.getStores();
          if (result.adminbannerimages) {
            this.offers = result.adminbannerimages;
          }
        }
      });
    }

    if (this.offers) {
      this.setOfferSectionWidth()
    }
  }

  getStores() {
    if (this.storeListBeyondRadius && this.storeListBeyondRadius?.length) {
      this.storeStartCount = this.storeStartCount + this.storeListBeyondRadius?.length;

      let counter = 0;
      for (let store of this.storeListBeyondRadius) {
        counter++;
        if (this.surveyBox2 && this.surveyBox1) {
        } else {
          if (counter === 3) {
            if (this.appComp.discover.value === 'stores') {
              $('#storeBeyondRadiusGallery').append(this.getSurveyTemplate());
              $('#mobstoreBeyondRadiusGallery').append(this.getMobileSurveyTemplate());
              $('[id^=rad]').change((ev: any) => {
                const radioId = Number($(ev.target).attr('id').substring(3));
                this.initSurvey(radioId);
              });
            }
          }

        }
        if (store.phoneNumber) {
          store.phoneNumber = store.phoneNumber;
        }
        if (this.appComp.discover.value === 'stores') {
          store.gmapLink = store.gmapLink;
        }
        if (this.appComp.discover.value === 'service centers') {
          store.gmapLink = 'https://www.google.com/maps/place/' + store.lat + ',' + store.lng
        }

        let storetemplate = this.getTemplate(store);
        let storemobtemplate = this.getStoreTempForMobile(store);

        $('#storeBeyondRadiusGallery').append($(storetemplate));
        $('#mobstoreBeyondRadiusGallery').append($(storetemplate));

        if (store && store.storeId) {
          document.getElementById('share_' + store.storeId)?.addEventListener('click', (e: MouseEvent) => {
            this.share(store);
            return false;
          })
        }
        if (store && store.storeId) {
          document.getElementById('sharemob_' + store.storeId)?.addEventListener('click', (e: MouseEvent) => {
            this.share(store);
            return false;
          })
        }
        if (store && store.storeId) {
          document.getElementById('callNow_' + store.storeId)?.addEventListener('click', (e: MouseEvent) => {
            this.showCallScreen(store);
            return false;
          })
        }
        if (store && store.storeId) {
          document.getElementById('callNowMob_' + store.storeId)?.addEventListener('click', (e: MouseEvent) => {
            this.showCallScreen(store);
            return false;
          })
        }
        if (store && store.storeId) {
          document.getElementById('viewMap_' + store.storeId)?.addEventListener('click', (e: MouseEvent) => {
            this.viewMap(e, 'mobile', store);
            return false;
          })
        }
      }
    }
  }


  getTemplate(store: any) {
    let storeTemp =
      `<div  class="result-item">` +
      `<div class="card-bx flex-column">` +
      `<div class="row p-0 m-0  mb-3">` +
      `<div class="col-9 p-0 mb-3">` +
      `<h4 class="pb-1">` + store.displayName + `<a href="javascript:;" id="share_` + store.storeId + `" class="share-icon share_icon_temp">&nbsp;</a></h4>` +
      `</div>` +
      `%mapLink%` +
      `%endTime%` +

      `<div class="col-4" style="padding-left:0px">` +
      `<span class="distance-txt">` + parseFloat(store.distance).toFixed(2) + ` km away</span>` +
      `</div>` +
      `</div>` +

      `<div class="row no-gutters">` +
      `<div class="col-8 pr-1">` +
      `<p class="pb-0">` + store.address + (store.city ? ', ' + store.city : '') + (store.state ? ', ' + store.state : '') + `</p>` +
      `%maskingNum%` +
      `%formattedaddress%` +
      `</div>` +
      `<div class="col-4">` +
      `<div class="clear-both">` +
      `%buyOnlineLink%` +
      `</div>` +
      `<div class="clear-both">` +
      `%bookademo%` +
      `</div>` +
      `</div>` +
      `</div>` +
      `</div>` +
      `</div>`;

    if (store.gmapLink) {
      storeTemp = storeTemp.replace('%mapLink%', ` <div class="col-3 pr-2 text-right">` +
        `<a href="` + store.gmapLink + `" target="_blank" class="directions">` + this.translate.instant('store-listing.store-card.link-get-directions') + `</a>` +
        `</div>`);
    } else {
      storeTemp = storeTemp.replace('%mapLink%', '');
    }

    if (store.sellertype !== "Reseller") {
      storeTemp = storeTemp.replace(`%mapView%`, `<a href="javascript:;" id="viewMap_` + store.storeId + `"class="view-button view-on-map">View On Map</a>`)
    } else {
      storeTemp = storeTemp.replace(`%mapView%`, ``)
    }

    storeTemp = storeTemp.replace('%endTime%', `<div class="col-5 p-0">` + `<span class="store-time open-icon">` + this.translate.instant('store-listing.store-card.business-hours') + " - " + this.utily.getTodayStoreTimings(store.timings) + `</span>` + `</div>`);


    if (store.maskingNum && this.appComp.discover.value === 'stores') {
      storeTemp = storeTemp.replace('%maskingNum%', `<p>` + `<a class="call-txt" href="javascript:;" id="callNow_` + store.storeId + `">` + this.translate.instant('store-listing.store-card.call-store') + ` - ` + store.maskingNum + `</a>` + `</p>`);
    } else if (store.maskingNum && this.appComp.discover.value === 'service centers') {
      storeTemp = storeTemp.replace('%maskingNum%', `<p>` + `<a class="call-txt" href="javascript:;" id="callNow_` + store.storeId + `">Call Service Center - ` + store.maskingNum + `</a>` + `</p>`);
    } else {
      storeTemp = storeTemp.replace('%maskingNum%', '');
    }

    if (store.formattedAddress) {
      storeTemp = storeTemp.replace('%formattedaddress%', `<p>` + `<a href="https://` + location.hostname + `/` + this.appComp.countryName + `/` + this.getLang() + `/store/` + store.formattedAddress + `"  class="view-store">` + this.translate.instant('store-listing.store-card.view-more-details') + `</a>` + `</p>`);
    } else {
      storeTemp = storeTemp.replace('%formattedaddress%', '');
    }

    if (store.buyOnlineLink) {
      storeTemp = storeTemp.replace('%buyOnlineLink%', `<a target="_blank" href="` + store.buyOnlineLink + `" class="button-gradient cta-book-post">Buy Online</a>`);
    } else {
      storeTemp = storeTemp.replace('%buyOnlineLink%', '');
    }

    if (store.bookademo == true) {
      storeTemp = storeTemp.replace('%bookademo%', `<a target="_blank" href="/bookademo?cid=in:other:9o8v9h" class="button-gradient cta-book-post">Book a Demo</a>`);
    } else {
      storeTemp = storeTemp.replace('%bookademo%', '');
    }

    return storeTemp;
  }
  count = 24
  getStoreTempForMobileOld(store: any) {
    this.count++;
    let storeTemp = `
    <div class="card-${this.count}">
    <div class="card-big-bx">
       <div class="white-bg-bx">
          <div class="top-section">
             <span class="store-name" style="
                font-weight: bold;
                margin-bottom: 10px;
                font-family: 'Montserrat-Bold';">${store.displayName}</span>
          </div>
          <div class="btm-section">
             <address style="font-size: 13px;
                line-height: 18px;
                color: #1e0013;
                font-weight: 500;
                font-family: 'Montserrat-Bold';">
                ${store.address + (store.city ? ', ' + store.city : '') + (store.state ? ', ' + store.state : '')}
             </address>
             <br />
             <span class="location-away" style="font-family: 'Montserrat-Bold';" >${parseFloat(store.distance).toFixed(2)} km away</span>
             <a _ngcontent-qpc-c104=""
                style="
                background: #ebebeb;
                border-radius: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                line-height: 18px;
                color: #1b2b81;
                font-weight: 600;
                width: 100%;
                font-family: 'Montserrat-Bold';
                margin-top: 17px;
                " href="javascript:;" class="share-but">Share</a>
             %mapLink%
             %endTime%
             <br /><br />
             %storedetails%
             <div class="btm-content">
                %maskingNum%
             </div>
          </div>
       </div>
       %mapView%
    </div>
 </div>`


    if (store.timings) {
      storeTemp = storeTemp.replace('%endTime%', `<span class="store-time open-icon float-left" style="ont-size: 14px;
      line-height: 18px;
      color: #1b2b81;
      font-weight: 600;
      display: block;
      position: relative;
      padding: 0 0 0 22px;
      font-family: 'Montserrat-Bold';
     margin-top: 10px;"> <strong>  ${this.translate.instant('store-listing.store-card.business-hours')} - ${this.utily.getTodayStoreTimings(store?.timings)} </strong> </span>`);
    } else {
      storeTemp = storeTemp.replace('%endTime%', '');
    }

    if (store.sellertype !== "Reseller") {
      storeTemp = storeTemp.replace(`%mapView%`, `<a href="javascript:;" id="viewMap_` + store.storeId + `"class="view-button view-on-map">View On Map</a>`)
    } else {
      storeTemp = storeTemp.replace(`%mapView%`, ``)
    }
    if (store.tmpMessage) {
      storeTemp = storeTemp.replace('%tmpMessage%', `<p class="temp_msg_mob">` + store.tmpMessage + `</p>`);
    } else {
      storeTemp = storeTemp.replace('%tmpMessage%', '');
    }

    if (store.gmapLink) {
      storeTemp = storeTemp.replace('%mapLink%', `<span class="get-direction"><a href="` + store.gmapLink + `" class="view-store" target="_blank">` + this.translate.instant('store-listing.store-card.link-get-directions') + `</a></span>`);
    } else {
      storeTemp = storeTemp.replace('%mapLink%', '');
    }

    if (store.maskingNum && this.appComp.discover.value === 'stores') {
      storeTemp = storeTemp.replace('%maskingNum%', `<a href="javascript:;" class="call-store-but" id="callNowMob_` + store.storeId + `">` + 'call store' + `</a>`);
    } else if (store.maskingNum && this.appComp.discover.value === 'service centers') {
      storeTemp = storeTemp.replace('%maskingNum%', `<a href="javascript:;" class="call-store-but" id="callNowMob_` + store.storeId + `">Call Service Center</a>`);
    } else {
      storeTemp = storeTemp.replace('%maskingNum%', '');
    }

    if (store.bookademo == true) {
      storeTemp = storeTemp.replace('%bookademo%', `<a href="/bookademo?cid=in:other:9o8v9h" class="demo-link">Book a Demo</a>`);
    } else {
      storeTemp = storeTemp.replace('%bookademo%', '');
    }

    if (store.buyOnlineLink) {
      storeTemp = storeTemp.replace('%buyOnlineLink%', `<a href="` + store.buyOnlineLink + `" class="demo-link">Book a Demo</a>`);
    } else {
      storeTemp = storeTemp.replace('%buyOnlineLink%', '');
    }

    if (this.appComp.discover.value === "stores") {
      storeTemp = storeTemp.replace('%storedetails%', `
    <span class="view-store-details"><a style="
      display: inline-block;
    text-decoration: none;
    font-size: 14px;
    line-height: 18px;
    color: #1b2b81;
    font-weight: 500;
    position: relative;
    color: black;
    font-family: 'Montserrat-Bold';
    margin-top: 10px;
    "href="https://` + location.hostname + `/` + this.appComp.countryName + `/` + this.getLang() + `/store/` + store.formattedAddress + `" >View Store Details</a></span>`);
    } else {
      storeTemp = storeTemp.replace('%storedetails%', '');
    }
    return storeTemp;
  }

  setOfferSectionWidth() {
    if (this.offers.length > 0) {
      this.renderer.setStyle(this.offerGallery.nativeElement.parentElement.parentElement, 'max-width', '1125px')
      this.renderer.setStyle(this.offerGallery.nativeElement, 'width', (375 * this.offers.length) + 15);
    }
  }

  getStoreTempForMobile(store: any) {
    this.count++;
    let template = '';
    template = `
    <div class="card-${this.count}">
    <div class="card-big-bx">
        <div class="white-bg-bx">
            <div class="top-section" style="height: 68px;">
                <span class="store-name" style="
                font-weight: bold;
                font-family: 'Montserrat-Bold';">
                    ${store?.displayName}</span
                >
            </div>
            <div class="btm-section">
                <address style="min-height: 75px !important;
                overflow-y: auto;
                max-height: 78px !important;" >
                    ${store?.address}<br />
                    ${store?.city},
                    ${store?.state}
                </address>
                <br />
                <div style="
                display: flex;
                justify-content: space-between;
            ">
                <span class="location-away" style="
                font-weight: bold;
                margin-bottom: 10px;
                font-family: 'Montserrat-Bold';">${parseFloat(store.distance).toFixed(2)}km away</span>
                %mapLink%
            </div>
                <a
                style="width: 100%;
                margin-top: 15px;"

                    href="javascript:;"
                    class="share-but"
                    (click)="share(store)">
                    ${this.translate.instant('store-listing.share')}</a
                ><br />
                <span
                    class="store-time open-icon float-left"
                    style="
                font-weight: bold;
                margin-bottom: 10px;
                font-family: 'Montserrat-Bold';"
                >
                    <strong>
                          ${this.translate.instant('store-listing.store-card.business-hours')}
                        -
                        ${this.utily.getTodayStoreTimings(store?.timings)}
                    </strong>
                </span>
                <br />
            <div class="view-section-call" style="display: flex;
            justify-content: space-between;
            position: absolute;
            width: 88%;
            margin-top: 15px;">

                <span class="view-store-details" style="
                font-weight: bold;
                margin-bottom: 10px;
                font-family: 'Montserrat-Bold';"><a href="https://` + location.hostname + `/` + this.appComp.countryName + `/` + this.getLang() + `/store/` + store.formattedAddress + `" >View Store Details</a></span>
                %buyOnlineLink%

                 </div>
                <div class="btm-content">
                 %maskingNum%
                </div>

            </div>
        </div>
        %mapView%
    </div>
</div>`

    if (store.sellertype !== "Reseller") {
      template = template.replace(`%mapView%`, `<a href="javascript:;" id="viewMap_` + store.storeId + `"class="view-button view-on-map" style="bottom: 27px;">  ${this.translate.instant('store-listing.label-view-on-map')}</a>`)
    } else {
      template = template.replace(`%mapView%`, ``)
    }

    if (store.gmapLink) {
      template = template.replace('%mapLink%', ` <span class="get-direction" style="
  font-weight: bold;
  margin-bottom: 10px;
  font-family: 'Montserrat-Bold';">
  <a
          href="${store?.gmapLink}"
          class="view-store"
          target="_blank"
          >${this.translate.instant('store-listing.store-card.link-get-directions')}</a></span>`);
    } else {
      template = template.replace('%mapLink%', '');
    }


    if (store.maskingNum && this.appComp.discover.value === 'stores') {
      template = template.replace('%maskingNum%', `<a style="top:0px;" href="javascript:;" class="call-store-but" id="callNowMob_${store.storeId}">` + 'call store' + `</a>`);
    } else if (store.maskingNum && this.appComp.discover.value === 'service centers') {
      template = template.replace('%maskingNum%', `<a href="javascript:;" class="call-store-but" id="callNowMob_` + store.storeId + `">Call Service Center</a>`);
    } else {
      template = template.replace('%maskingNum%', '');
    }

    if (store.buyOnlineLink) {
      template = template.replace('%buyOnlineLink%', `
  <span class="view-store-details"
  style="font-weight: bold;
  margin-bottom: 10px;
  font-family: 'Montserrat-Bold';">  <a href="${store.buyOnlineLink}" class="demo-link" target="_blank">Buy Online</a>
  </span>`);
    } else {
      template = template.replace('%buyOnlineLink%', '');
    }

    return template;
  }

  toggleDiscovery(ev: any) {

    $('#storeGallery').empty();
    $('#storeBeyondRadiusGallery').empty();
    $('#mobstoreGallery').empty();
    $('#mobstoreBeyondRadiusGallery').empty();
    this.storeStartCount = 0;
    this.storeStartNum = 25;
    let request: StoresRequest;

    if (this.appComp.discover.value === 'stores') {
      this.appComp.discover.next('service centers');
      this.renderer.setStyle(document.querySelector('#tabContStores'), 'display', 'none');
      this.renderer.setStyle(document.querySelector('#tabContServiceCenters'), 'display', 'block');
      if (this.appComp.placeId) {
        this.getStoreByPlaceId(this.appComp.placeId, this.appComp.placeDesc, null, null, null);
      } else {
        request = new StoresRequest(this.appComp.currentLocation.lat, this.appComp.currentLocation.lng, 4, this.recNum, 0);
        this.appComp.getServiceCentersBasedOnLocation(request);
      }

    } else {
      this.appComp.discover.next('stores');
      this.renderer.setStyle(this.tabContServiceCenters.nativeElement, 'display', 'none');
      this.renderer.setStyle(this.tabContStores.nativeElement, 'display', 'block');
      if (this.appComp.placeId) {
        this.getStoreByPlaceId(this.appComp.placeId, this.appComp.placeDesc, null, null, null);
      } else {
        request = new StoresRequest(this.appComp.currentLocation.lat, this.appComp.currentLocation.lng, 2, this.recNum, 0);
        this.appComp.getStoresBasedOnLocation(request);
      }
    }
  }

  resetSearch() {
    this.appComp.placeId = undefined;
    this.appComp.placeDesc = undefined;
    this.searchString = undefined;
    this.searchLocation.nativeElement.value = '';
    this.mobilePlacesSearchInput.nativeElement.value = '';

    if (this.appComp.discover.value === 'stores') {
      const request = new StoresRequest(this.appComp.currentLocation.lat, this.appComp.currentLocation.lng, 2, this.recNum, 0);
      this.appComp.getStoresBasedOnLocation(request);
    } else {
      const request = new StoresRequest(this.appComp.currentLocation.lat, this.appComp.currentLocation.lng, 4, this.recNum, 0);
      this.appComp.getServiceCentersBasedOnLocation(request);
    }
  }

  rated: any
  initSurvey(selection: any) {
    this.rated = selection;
    let dataLayer = (<any>window)['dataLayer'];
    dataLayer.push({ 'event': 'pop-cx-form-stage-1-engagement-' + this.appComp.surveyDisplayMode + '-store-listing-rating' });
    dataLayer.push({ 'event': this.appComp.surveyDisplayMode + '-store-listing' + '-question-1-' + selection });
    this.showSurvey();
    this.fetchQuestions(selection);
    this.initialStar = 0;
  }

  showSurvey() {
    $('#surveyScreen').css({ 'right': '0%', 'visibility': 'visible' });
    $('.survey-screen-bx .survey-second-screen').css({ 'bottom': '0%', 'visibility': 'visible' });
    $('#radi').click();

    //this.modalService.dismissAll();

  }
  sizeRange = "30px";
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (window.innerWidth >= 400) {

      this.sizeRange = "22px";
    }

  }
  rating3!: number;
  checkedcolor = "#1B2B81";
  uncheckedcolor = "#e3e4f0";
  showSurveyNext(val: any, ev: any) {
    this.fetchingQuestions = false;
    if (val === 1) {
      this.setNextScreen(ev)
    }

    // if (val === 2) {

    //   this.setNextScreen(ev);
    // }

    if (val === 3) {

      if (this.checkIdsArr.length === 0) {
        alert('Please select at least one or more options.');
        return;
      }

      let dataLayer = (<any>window)['dataLayer'];
      dataLayer.push({ 'event': 'pop-cx-form-stage-2-engagement-' + this.appComp.surveyDisplayMode + '-store-listing' });
      if ($('#range').length) {
        const range = document.querySelector("#range")
        var labels = { 0: '0', 1: '1', 2: '2', 3: '3', 4: '4', 5: '5', 6: '6', 7: '7', 8: '8', 9: '9', 10: '10' };
        this.slider = noUiSlider.create(range, {
          start: 10,
          connect: [true, false],
          tooltips: {
            to: function (value: any) {
              return value > 10 ? '10+' : parseInt(value)
            }
          },
          range: {
            'min': 0,
            'max': 10
          },
          step: 1,
          pips: {
            mode: 'steps',
            filter: function (value: any, type: {}) {
              return type === 0 ? -1 : 1;
            },
            format: {
              to: function (value: string) {
                return value;
              }
            }
          }
        });
      }


      /** third rating */
      if (this.starratingValue < 0) {
        alert("Please select a rating.");

      } else {
        let dataLayer = (<any>window)['dataLayer'];
        dataLayer.push({ 'event': 'pop-cx-form-submit-' + this.appComp.surveyDisplayMode + '-store-listing' });
        dataLayer.push({ 'event': this.appComp.surveyDisplayMode + '-store-listing' + '-question-2-' + this.starratingValue.toString() });
        this.questions2 = this.starratingValue.toString();
        const payload = { checkedIds: this.checkIdsArr.join(","), questions1: this.questions1, questions2: Number(this.questions2), source_id: 150, url: location.hostname + '/cx?sid=150' }
        this.apiService.submitSurvey(payload).then((resp) => {
          if (resp.status === 'SUCCESS') {
            this.setNextScreen(ev);
          }
        })
      }
      // if (this.captcha === undefined) {
      //   alert('Please complete captcha before submitting your response.')
      // }

    }
  }

  setNextScreen(ev: any) {
    const elem = ev.currentTarget === null ? ev.target : ev.currentTarget;
    $(elem).parents('.survey-screen').css({ 'bottom': '100%', 'visibility': 'hidden' });
    $(elem).parents('.survey-screen').next('.survey-screen').css({ 'bottom': 0, 'visibility': 'visible' });
  }


  fetchQuestions(rating: string) {
    this.fetchingQuestions = true;

    this.apiService.getSurveyRating(this.appComp.surveySourceId, rating).then((resp: SurveyResponse) => {

      if (resp.status === 'SUCCESS') {
        this.surveyRatingQuestions = resp.cities;
        this.fetchingQuestions = false;
      }
    });

    this.questions1 = rating;
  }

  closeSurveyScreen() {
    $('#surveyScreen').css({ 'right': '-100%', 'visibility': 'hidden' });
    setTimeout(() => {
      $('#surveyScreen .survey-screen').css({ 'bottom': '-100%' });
      $('.survey-screen-bx .survey-main-screen').css({ 'bottom': '0%' });
    }, 1000);

    this.ele.nativeElement.querySelectorAll(".emoji_bg").forEach((emoji: any) => {
      emoji.classList.remove('selected');
    });

    if (this.slider) {
      this.slider.destroy();
    }

    this.surveyRatingQuestions = [];
    this.checkIdsArr = [];
    this.starratingValue = -1;

    if (this.surveyModalRef) {
      this.surveyModalRef.dismiss();
    }
    clearInterval(this.surveyTimer);
    this.surveyDisplayed = false;
    if (this.star1) {
      this.star1.value = 0;
    }
    if (this.star2) {
      this.star2.value = 0;
    }
    if (this.star3) {
      this.star3.value = 0;
    }

    //noUiSlider = null;
  }

  captureResponse(resp: any) {
    const idx = this.checkIdsArr.indexOf(resp)
    if (idx === -1) {
      this.checkIdsArr.push(resp);
    } else {
      this.checkIdsArr.splice(idx, 1);
    }
  }


  verifyCallback(resp: string) {
    this.captcha = resp;
  }

  startSearch(ev: any) {
    if ((ev.type === 'keypress' && ev.code === 'Enter') || (ev.type === 'click')) {
      this.appComp.isLoad = true;
      // this.apiService.getPlaces($('#searchLocationDesktop').val()).then(result => {
      //   if (result.status === 'SUCCESS') {
      //     this.places = result.results;
      //   }
      // });
      this.apac.autoSearch($('#searchLocationDesktop').val(), this.appComp.tenantId, this.appComp.countryName, this.appComp.localeId.split("-")[0]).subscribe(result => {
        this.appComp.isLoad = false;
        this.places = result;

      })
    }
  }

  detectEscape(ev: any) {

  }

  startMobileSearch(ev: any) {
    if (this.appComp.discover.value === 'stores') {
      if ((ev.type === 'keypress' && ev.code === 'Enter' && ev.target.id === 'mobilePlacesSearchInput1') || (ev.type === 'click' && ev.target.id === 'mobilePlacesSearchButton1') || (ev.type === 'click' && ev.target.id === 'mobilePlacesSearchButton3')) {
        this.searchString = $('#mobilePlacesSearchInput1').val() || undefined;
        // this.apiService.getPlaces($('#mobilePlacesSearchInput1').val()).then(result => {
        //   if (result.status === 'SUCCESS') {
        //     this.places = result.results;
        //     $('#mobileSearchDropdown1').hide();
        //     $('#mobilSearchListing1').show();
        //   }
        // });
        this.apac.autoSearch($('#mobilePlacesSearchInput1').val(), this.appComp.tenantId, this.appComp.countryName, this.appComp.localeId.split("-")[0]).subscribe(result => {

          this.places = result;
          $('#mobileSearchDropdown1').hide();
          $('#mobilSearchListing1').show();
        })
      }
    } else if (this.appComp.discover.value === 'service centers') {
      if ((ev.type === 'keypress' && ev.code === 'Enter' && ev.target.id === 'mobilePlacesSearchInput2') || (ev.type === 'click' && ev.target.id === 'mobilePlacesSearchButton2') || (ev.type === 'click' && ev.target.id === 'mobilePlacesSearchButton4')) {
        this.searchString = $('#mobilePlacesSearchInput2').val() || undefined;
        // this.apiService.getPlaces($('#mobilePlacesSearchInput2').val()).then(result => {
        //   if (result.status === 'SUCCESS') {
        //     this.places = result.results;
        //     $('#mobileSearchDropdown2').hide();
        //     $('#mobilSearchListing2').show();
        //   }
        // });
        this.apac.autoSearch($('#mobilePlacesSearchInput2').val(), this.appComp.tenantId, this.appComp.countryName, this.appComp.localeId.split("-")[0]).subscribe(result => {

          this.places = result;
          $('#mobileSearchDropdown2').hide();
          $('#mobilSearchListing2').show()
        })
      }
    }
  }

  recordSearchString(ev: any) {
    this.searchString = ev.target.value;
  }

  getStoreByPlaceId(placeId: any, placeDesc: any, latitude: any, longitude: any, ev: any) {
    if (this.appComp.locationOn.value === false) {
      this.appComp.locationOn.next(true);
    }
    const request = new StoresRequest(0, 0, this.appComp.discover.value === 'stores' ? 2 : 4, this.recNum, 0);
    request.lat = undefined;
    request.lng = undefined;
    request.placeId = placeId;
    this.appComp.placeId = placeId;
    this.appComp.placeDesc = placeDesc;
    this.appComp.latitude = latitude;
    this.appComp.longitude = longitude;
    this.appComp.currentLocationName = (this.appComp.placeDesc ? this.appComp.placeDesc.substr(0, this.appComp.placeDesc.length - 1) : "India").split(",")[0];
    // this.appComp.currentLocationName = this.appComp.currentLocationName.substr(0, this.appComp.currentLocationName.length - 1);
    this.searchString = placeDesc;
    $('#searchLocation').val(placeDesc);
    $('#mobilePlacesSearchInput1').val(placeDesc);
    $('#mobilePlacesSearchInput2').val(placeDesc);
    this.places = [];
    this.hideMobileListing();

    if (this.appComp.discover.value === 'stores') {

      this.appComp.isLoad = true;
      let sellertype = this.appComp.sellertype;
      forkJoin(this.apac.getStoreDetailsByPlaceId(this.appComp.tenantId, request.placeId, this.appComp.distance, this.appComp.record, "s", this.appComp.page, this.appComp.countryName, this.appComp.localeId.split("-")[0], sellertype),
        this.apac.getStoreListWithInKm(this.appComp.type, this.appComp.latitude, this.appComp.longitude, this.appComp.distance, this.appComp.countryName, this.appComp.localeId.split("-")[0], sellertype))
        .subscribe(([result, result2]) => {
          this.appComp.isLoad = false;
          $('#storeGallery').empty();
          $('#storeBeyondRadiusGallery').empty();
          $('#mobstoreGallery').empty();
          $('#mobstoreBeyondRadiusGallery').empty();
          this.storeNumWithinRadius = result2[0].store_within_radius;
          this.storeNumBeyondRadius = result2[0].total_store;
          this.foundStore = "Found " + this.storeNumWithinRadius + ' stores near you. (' + this.storeNumBeyondRadius + ' stores beyond 25Km)';
          this.stores = result;
          // if (this.stores && this.stores.length > 3) {
          //   this.stores.splice(4, 0, new Store());
          // }
          this.placeIdRequest = true;
          if (result.adminbannerimages) {
            this.offers = result.adminbannerimages;
          }
          if (!this.isDesktop) {
            this.showStoreListing();
          }
        }, err => {
          this.appComp.isLoad = false;
        })
      // this.apiService.getStoresForLocation(request).then(result => {
      //   if (result.status === 'SUCCESS') {
      //     $('#storeGallery').empty();
      //     $('#storeBeyondRadiusGallery').empty();
      //     $('#mobstoreGallery').empty();
      //     $('#mobstoreBeyondRadiusGallery').empty();
      //     this.storeNumWithinRadius = result.storeNumWithinRadius;
      //     this.storeNumBeyondRadius = result.storeNumBeyondRadius;
      //     //this.storesCount.next(0);
      //     this.storesCount.next(result.storeNumWithinRadius);
      //     this.stores = result.listWithinRadius;
      //     if (this.stores && this.stores.length > 3) {
      //       this.stores.splice(4, 0, new Store());
      //     }
      //     this.placeIdRequest = true;
      //     if (result.adminbannerimages) {
      //       this.offers = result.adminbannerimages;
      //     }
      //     this.getStoresByPlace();
      //     if (!this.isDesktop) {
      //       this.showStoreListing();
      //     }
      //   }
      // });
    } else {
      let sellertype = this.appComp.sellertype;
      forkJoin(this.apac.getStoreDetailsByPlaceId(this.appComp.tenantId, request.placeId, this.appComp.distance, this.appComp.record, "sc", this.appComp.page, this.appComp.countryName, this.appComp.localeId.split("-")[0], sellertype),
        this.apac.getStoreListWithInKm(this.appComp.type, request.lat, request.lng, this.appComp.distance, this.appComp.countryName, this.appComp.localeId.split("-")[0], sellertype))
        .subscribe(([result, result2]) => {
          $('#storeGallery').empty();
          $('#storeBeyondRadiusGallery').empty();
          $('#mobstoreGallery').empty();
          $('#mobstoreBeyondRadiusGallery').empty();
          this.storeNumWithinRadius = result2[0].store_within_radius;
          this.storeNumBeyondRadius = result2[0].total_store;
          this.foundStore = "Found " + this.storeNumWithinRadius + ' stores near you. (' + this.storeNumBeyondRadius + ' stores beyond 25Km)';
          this.stores = result;
          // if (this.stores && this.stores.length > 3) {
          //   this.stores.splice(4, 0, new Store());
          // }
          this.placeIdRequest = true;
          if (result.adminbannerimages) {
            this.offers = result.adminbannerimages;
          }
          if (!this.isDesktop) {
            this.showStoreListing();
          }
        })
    }
  }

  // getStoreByPlaceIdOne() {

  //     forkJoin(this.apac.getStoreDetailsByPlaceId(this.appComp.tenantId, this.appComp.placeId, this.appComp.distance, this.appComp.record, "s", this.appComp.page ,this.appComp.countryName, this.appComp.localeId.split("-")[0],this.appComp.sellertype),
  //     this.apac.getStoreListWithInKm(this.appComp.type,request.lat,request.lng, this.appComp.distance,this.appComp.countryName,  this.appComp.localeId.split("-")[0], this.appComp.sellertype))
  //     .subscribe(([result, result2 ]) =>{
  //     $('#storeGallery').empty();
  //         $('#storeBeyondRadiusGallery').empty();
  //         $('#mobstoreGallery').empty();
  //         $('#mobstoreBeyondRadiusGallery').empty();
  //         this.storeNumWithinRadius = result2[0].store_within_radius;
  //         this.storeNumBeyondRadius = result2[0].total_store;
  //         this.foundStore = "Found " + this.storeNumWithinRadius + ' stores near you. (' + this.storeNumBeyondRadius + ' stores beyond 25Km)';
  //         this.stores = result;
  //         // if (this.stores && this.stores.length > 3) {
  //         //   this.stores.splice(4, 0, new Store());
  //         // }
  //         this.placeIdRequest = true;
  //         if (result.adminbannerimages) {
  //           this.offers = result.adminbannerimages;
  //         }
  //         if (!this.isDesktop) {
  //           this.showStoreListing();
  //         }
  //     })
  //     // this.apiService.getStoresForLocation(request).then(result => {
  //     //   if (result.status === 'SUCCESS') {
  //     //     $('#storeGallery').empty();
  //     //     $('#storeBeyondRadiusGallery').empty();
  //     //     $('#mobstoreGallery').empty();
  //     //     $('#mobstoreBeyondRadiusGallery').empty();
  //     //     this.storeNumWithinRadius = result.storeNumWithinRadius;
  //     //     this.storeNumBeyondRadius = result.storeNumBeyondRadius;
  //     //     //this.storesCount.next(0);
  //     //     this.storesCount.next(result.storeNumWithinRadius);
  //     //     this.stores = result.listWithinRadius;
  //     //     if (this.stores && this.stores.length > 3) {
  //     //       this.stores.splice(4, 0, new Store());
  //     //     }
  //     //     this.placeIdRequest = true;
  //     //     if (result.adminbannerimages) {
  //     //       this.offers = result.adminbannerimages;
  //     //     }
  //     //     this.getStoresByPlace();
  //     //     if (!this.isDesktop) {
  //     //       this.showStoreListing();
  //     //     }
  //     //   }
  //     // });

  // }


  hideMobileListing() {
    $('#mobilSearchListing1').parent('.input-bx').removeClass('open');
    $('#mobileSearchDropdown1').hide();
    $('#mobilSearchListing1').hide();
    $('#mobilSearchListing2').parent('.input-bx').removeClass('open');
    $('#mobileSearchDropdown2').hide();
    $('#mobilSearchListing2').hide();

  }

  toggleLocation(ev: any) {
    if (ev.target.checked) {
      this.appComp.locationOn.next(true);
    } else {
      this.appComp.locationOn.next(false);
    }
  }

  setCurrentLocation() {
    $('#mobilePlacesSearchInput1').val('');
    $('#mobilePlacesSearchInput2').val('');
    this.hideMobileListing();
    this.appComp.checkLocationSetting();
    this.searchString = undefined;
    this.appComp.placeId = undefined;
    this.appComp.placeDesc = undefined;

  }

  viewMap(ev: any, _for: any, store?: Store) {
    this.mapOpened = true;

    if (_for === 'desktop') {
      $('#deskMapContainer').show().animate({ 'bottom': 65 });
      this.fullMap(this.stores, $('#map_canvas'));
    } else if (_for = 'mobile') {
      $('#mapSection').show().animate({ 'bottom': 0, 'right': 0 });

      if (store) {
        this.singleStore.push(store);
      }
      this.fullMap(this.singleStore, $('#map_canvas_mobile'));
    }

  }

  closeMap() {
    $('#deskMapContainer').animate({ 'bottom': '-100%' }).hide();
    this.mapOpened = false;
    this.singleStore = [];
  }

  fullMap(stores: any, mapHolder: any) {
    let params: any;
    let changeViewClicked = false;
    let googleMap;

    const markerIcon = 'responsive/img/marker_icon.png';

    // Get store list from storesList.js
    let storesData = stores;
    let bounds = new google.maps.LatLngBounds();
    let lat = stores[0].lat;
    let lon = stores[0].lng;

    let latLon = new google.maps.LatLng(lat, lon);
    let zoom = 18;

    if (this.appComp.discover.value === 'stores') {
      this.mapResultText.nativeElement.innerHTML = "Found " + stores.length + ' stores near you.';
    } else if (this.appComp.discover.value === 'service centers') {
      this.mapResultText.nativeElement.innerHTML = "Found " + stores.length + ' service centers near you.';
    }

    var mapOptions = {
      center: latLon,
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
    };

    const mapFull = new google.maps.Map(mapHolder[0], mapOptions);

    let infoWindow = new google.maps.InfoWindow();

    let index = 0;
    for (let store of stores) {
      let position = new google.maps.LatLng(store.lat, store.lng);
      let infoContent = `<div class="result-listing-bx-info">` +
        `<div  class="result-item">` +
        `<div class="card-bx-info flex-column">` +
        `<div class="row p-0 m-0  mb-3">` +
        `<div class="col-sm-8 col-lg-9 pr-1 p-0 mb-3">` +
        `<h4 class="pb-1">` + store.name + `<a href="javascript:" name="share_info_` + store.storeId + `" class="share-icon">&nbsp;</a></h4>` +
        `</div>` +
        `%mapLink%` +
        `%tmpMessage%` +
        `%endTime%` +
        `<div class="col-4" style="padding-left:0px">` +
        `<span class="distance-txt">` + parseFloat(store.distance).toFixed(2) + ` km away</span>` +
        `</div>` +
        `</div>` +

        `<div class="row no-gutters">` +
        `<div class="col-8 pr-1">` +
        `<p class="pb-0">` + store.address + (store.city ? ', ' + store.city : '') + (store.state ? ', ' + store.state : '') + `</p>` +
        `%maskingNum%` +
        `%formattedaddress%` +
        `</div>` +
        `<div class="col-4">` +
        `<div class="clear-both">` +
        `%buyOnlineLink%` +
        `</div>` +
        `<div class="clear-both">` +
        `%bookademo%` +
        `</div>` +
        `</div>` +
        `</div>` +
        `</div>` +
        `</div>`;

      if (store.gmapLink) {
        infoContent = infoContent.replace('%mapLink%', `<div class="col-sm-4 col-lg-3 pr-2 text-right">` + `<a href="` + store.gmapLink + `" target="_blank" class="directions p-0">` + this.translate.instant('store-listing.store-card.link-get-directions') + `</a>` + `</div>`);
      } else {
        infoContent = infoContent.replace('%mapLink%', '');
      }

      if (store.tmpMessage) {
        infoContent = infoContent.replace('%tmpMessage%', `<div class="col-8 p-0">` +
          `<p class="mb-3" style="color:#4ac0e0">` + store.tmpMessage + `</p>` + `</div>`);
      } else {
        infoContent = infoContent.replace('%tmpMessage%', '');
      }

      if (store.timings && !store.tmpMessage) {
        infoContent = infoContent.replace('%endTime%', `<div class="col-5 p-0">` + `<span class="store-time open-icon">` + this.translate.instant('store-listing.store-card.business-hours') + " - " + this.utily.getTodayStoreTimings(store.timings) + `</span>` + `</div>`);
      } else {
        infoContent = infoContent.replace('%endTime%', '');
      }

      if (store.phoneNumber && this.appComp.discover.value === 'stores') {
        infoContent = infoContent.replace('%maskingNum%', `<p>` + `<a class="call-txt" href="tel:` + store.phoneNumber + `">` + this.translate.instant('store-listing.store-card.view-more-details') + ` - ` + store.phoneNumber + `</a>` + `</p>`);
      } else if (store.phoneNumber && this.appComp.discover.value === 'service centers') {
        infoContent = infoContent.replace('%maskingNum%', `<p>` + `<a class="call-txt" href="tel:` + store.phoneNumber + `">Call Service Center - ` + store.phoneNumber + `</a>` + `</p>`);
      } else {
        infoContent = infoContent.replace('%maskingNum%', '');
      }

      if (store.formattedAddress) {
        infoContent = infoContent.replace('%formattedaddress%', `<p>` + `<a target="_blank" href="https://` + location.hostname + `/` + this.appComp.countryName + `/` + this.getLang() + `/store/` + store.formattedAddress + `"  class="view-store">` + this.translate.instant('store-listing.store-card.view-more-details') + `</a>` + `</p>`);
      } else {
        infoContent = infoContent.replace('%formattedaddress%', '');
      }

      if (store.buyOnlineLink) {
        infoContent = infoContent.replace('%buyOnlineLink%', `<a target="_blank" href="` + store.buyOnlineLink + `" class="button-gradient cta-book-post">Buy Online</a>`);
      } else {
        infoContent = infoContent.replace('%buyOnlineLink%', '');
      }

      if (store.bookademo == true) {
        infoContent = infoContent.replace('%bookademo%', `<a href="/bookademo?cid=in:other:9o8v9h" class="button-gradient cta-book-post">Book a Demo</a>`);
      } else {
        infoContent = infoContent.replace('%bookademo%', '');
      }

      bounds.extend(position);

      let fullMapMarker = new google.maps.Marker({
        position: position,
        map: mapFull,
        title: store.name,
        icon: 'https://buyalenovo.com/images/pushpin.png'
      });

      google.maps.event.addListener(fullMapMarker, 'click', (function (fullMapMarker, index) {
        return function () {
          infoWindow.setContent(infoContent);
          infoWindow.open(mapFull, fullMapMarker);
          mapFull.setCenter(fullMapMarker.position);

        }
      })(fullMapMarker, index));



    };

    // mapFull.setCenter(latLon);

    // Fit all bounds once, when the map is ready
    google.maps.event.addListenerOnce(mapFull, 'idle', function () {

      fitAllBounds(bounds);
    });

    function fitAllBounds(b: any) {

      // Get north east and south west markers bounds coordinates
      var ne = b.getNorthEast();
      var sw = b.getSouthWest();

      // Get the current map bounds
      var mapBounds = mapFull.getBounds();

      // Check if map bounds contains both north east and south west points
      if (mapBounds.contains(ne) && mapBounds.contains(sw)) {

        // Everything fits
        return;

      } else {

        var mapZoom = mapFull.getZoom();

        if (mapZoom > 0) {

          // Zoom out
          mapFull.setZoom(mapZoom - 1);

          // Try again
          fitAllBounds(b);
        }
      }
    }





    /* function initializeMap() {
      initialize(params);
    }

    return {
      initializeFullMap: initializeMap
    } */

  }

  lazyLoadGoogleMap() {
    /* $.getScript("https://maps.google.com/maps/api/js?key=AIzaSyDETO4TcmT1YWodgGTyFQuNok3l9Kipt9Q&sensor=true&callback=initializeMap")
      .done(function (_script: any, _textStatus: any) {
        //alert("Google map script loaded successfully");
      })
      .fail(function (_jqxhr: any, _settings: any, _ex: any) {
        //alert("Could not load Google Map script: " + jqxhr);
      }); */


    google.load("maps", "3", { callback: this.initializeMap, other_params: "key=AIzaSyD6emsHoKqSzFPnHJG8Kyf6T9PcDBIf4pQ" });
  }

  initializeMap = () => (resp: any) => {
  }

  share(store: any) {
    let text = '', title = '', url = '';
    text = "";
    if (store.phoneNumber) {
      text += "Phone: " + store.phoneNumber;
    }
    if (store.email) {
      text += ", \n ";
      text += "Email: " + store.email;
    }
    if (store.address) {
      text += ", \n ";
      text += "Address: " + store.address + + (store.city ? ', ' + store.city : '') + (store.state ? ', ' + store.state : '');
    }
    if (store.formattedAddress) {
      text += ", \n ";
      text += "Website: " + store.formattedAddress;
    }
    if (store.gmapLink) {
      text += ", \n ";
      text += "Location map: " + store.gmapLink;
    }

    if (store.name) {
      title = store.name;
    }

    if (store.formattedAddress) {
      url = `https://${location.hostname}/${this.appComp.countryName}/${this.getLang()}/store/${store.formattedAddress}`
      // url = this.appComp.countryName + '/'+ this.getLang() + '/store/' + store.formattedAddress;
    }

    this.sharingService.share(text, title, url).then(() => {
      //do nothing
    })
  }
  openViewList() {
    $('#viewListingScreen').show().animate({ 'bottom': 0 });
    $('.card-big-bx .btm-section').css('position', 'relative');
  }


  closeViewListingScreen(type: string) {
    if (type === 'all') {
      $('#storeListingScreen').animate({ 'right': '-100%' }, function () {
        $('#storeListingScreen').hide();
      });
    }
    // $('.card-big-bx .btm-section').css('position', 'static');
    $('#mapSection').animate({ 'right': '-100%' }, function () {
      $('#mapSection').hide().animate({ 'bottom': '-100%', 'right': 0 });
    });
    $('#viewListingScreen').animate({ 'right': '-100%' }, function () {
      $('#viewListingScreen').hide().animate({ 'bottom': '-100%', 'right': 0 });
    });
    $('.screen-tab-content').scrollLeft(0);
    this.singleStore = [];
  }

  showCallScreen(store: any) {
    this.storeName = store.displayName;
    this.storeNumber = store.maskingNum;
    $('#callSlideSection').show().animate({ 'bottom': 0 });
  }

  resolved(captchaResponse: any) {
    this.captcha = captchaResponse;
  }

  getLocationByIp() {
    this.appComp.locationOn.next(true);
  }

  locateMe() {
    this.appComp.locationOn.next(true);
    $('#storeListingScreen').show().animate({ 'right': 0 });
  }

  getStoresByCity(lat: number, lng: number, city: string) {
    $('#countries').hide();
    this.appComp.countryListShow = false;
    this.appComp.locationOn.next(true);
    this.appComp.setUserLocation(new Location(lat, lng));
    this.appComp.currentLocationName = city;
  }

  getStoresByPlaceChange(placeId: any, placeDesc: any, lat: any, lng: any, ev: any) {
    if (this.appComp.locationOn.value === false) {
      this.getStoreByPlaceId(placeId, placeDesc, lat, lng, ev);
      $('#storeListingScreen').show().animate({ 'right': 0 });
    } else {
      this.getStoreByPlaceId(placeId, placeDesc, lat, lng, ev);
    }

  }

  showStoreListing() {
    $('#storeListingScreen').show().animate({ 'right': 0 }, 500, () => {
      setInterval(() => {
        const w = $('.desktop-rating-wrapper > star-rating');

        if (w && w.length > 0) {
          for (let i = 0; i < w.length; i++) {
            $(w[i].shadowRoot).find('div').css('display', 'flex').css('justify-content', 'space-evenly').css('padding', '10px 0');
          }
        }
      }, 1000);

      setInterval(() => {
        const n = $('.desktop-nps-rating > star-rating');
        if (n && n.length > 0) {
          for (let i = 0; i < n.length; i++) {
            $(n[i].shadowRoot).find('div').css('display', 'flex').css('justify-content', 'space-evenly').css('padding', '10px 0');
          }
        }
      }, 1000);
    });

  }

  showServiceCenterListing() {
    $('#storeListingScreen').show().animate({ 'right': 0 }, 500);
  }

  showMobileSearchWindow(ev: any) {
    const el = ev.currentTarget;
    if (!$(el).parents('.input-bx').hasClass('open')) {
      $(el).parents('.input-bx').addClass('open')
      $(el).parents('.input-bx').find('.searchDropdown').slideDown();
      $(el).select();
    } else {
      $(el).parents('.input-bx').removeClass('open')
      $(el).parents('.input-bx').find('.searchDropdown').hide();
      $(el).parents('.input-bx').find('.searchListing').hide();
      $(el).select();
    }
  }

  contentTopPad() {
    var headerHeight = $('header').height() > 56 ? 56 : $('header').height() >=56 ?  $('header').height() : 56;
    $('.content').css({ paddingTop: headerHeight });
  }

  getSurveyTemplate(): string {
    return `<div class="result-item"><div
    class="card-bx flex-column survey-intro-screen  justify-content-center">
    <div
        class="d-flex justify-content-center align-items-center justify-content-between">
        <div class="d-flex">
            <span class="col-4 d-flex flex-column">
                <span class="survey-title">Take Our Survey</span>
                <img src="./assets/images/survey-img.png"
                    width="165" height="128" alt=""
                    class="img-fluid">
            </span>
            <span class="col-8 d-flex flex-column">
                <p class="survey-desp">How do you rate your
                    experience of finding a nearby Lenovo store?
                    <span class="sub-txt">Please rate on a scale of
                        1-5 where 5 is very good and 1 is very
                        poor.</span>
                </p>
                <div
                    class="survey-number-bx survey-content d-flex justify-content-center pt-3">
                    <!-- <img src="images/survey-number-img.png" width="297" height="63" alt="" class="img-fluid"> -->
                    <div class="radio-bx">
                        <ul class="clearfix">
                            <li>
                                <span class="rad-num">1</span>
                                <div class="radio-main">
                                    <input type="radio"
                                        name="radio1"
                                        class="radio-but" id="rad1"
                                        (change)="initSurvey('1')">
                                    <label for="rad1"></label>
                                    <div class="check"></div>
                                </div>
                            </li>
                            <li>
                                <span class="rad-num">2</span>
                                <div class="radio-main">
                                    <input type="radio"
                                        name="radio1"
                                        class="radio-but" id="rad2"
                                        (change)="initSurvey('2')">
                                    <label for="rad2"></label>
                                    <div class="check"></div>
                                </div>
                            </li>
                            <li>
                                <span class="rad-num">3</span>
                                <div class="radio-main">
                                    <input type="radio"
                                        name="radio1"
                                        class="radio-but" id="rad3"
                                        (change)="initSurvey('3')">
                                    <label for="rad3"></label>
                                    <div class="check"></div>
                                </div>
                            </li>
                            <li>
                                <span class="rad-num">4</span>
                                <div class="radio-main">
                                    <input type="radio"
                                        name="radio1"
                                        class="radio-but" id="rad4"
                                        (change)="initSurvey('4')">
                                    <label for="rad4"></label>
                                    <div class="check"></div>
                                </div>
                            </li>
                            <li>
                                <span class="rad-num">5</span>
                                <div class="radio-main">
                                    <input type="radio"
                                        name="radio1"
                                        class="radio-but" id="rad5"
                                        (change)="initSurvey('5')">
                                    <label for="rad5"></label>
                                    <div class="check"></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </span>
        </div>
    </div>
</div></div>`;
  }
  getMobileSurveyTemplate(): string {
    return `<div class="card-big-bx"><div class="card-big-bx survey-bg">
    <div class="survey-intro-screen">
        <span class="survey-title">Would you like to let us know about your
            experience with Lenovo ?</span>
        <span>
            <img src="./assets/images/survey-img.png" width="273" height="212"
                alt="" class="img-fluid">
        </span>
        <div
            class="survey-number-bx survey-content d-flex justify-content-center pt-3">
            <div class="radio-bx">
                <ul class="clearfix">
                    <li>
                        <span class="rad-num">1</span>
                        <div class="radio-main">
                            <input type="radio" name="radio1" class="radio-but"
                                id="rad1" (change)="initSurvey('1')">
                            <label for="rad1"></label>
                            <div class="check"></div>
                        </div>
                    </li>
                    <li>
                        <span class="rad-num">2</span>
                        <div class="radio-main">
                            <input type="radio" name="radio1" class="radio-but"
                                id="rad2" (change)="initSurvey('2')">
                            <label for="rad2"></label>
                            <div class="check"></div>
                        </div>
                    </li>
                    <li>
                        <span class="rad-num">3</span>
                        <div class="radio-main">
                            <input type="radio" name="radio1" class="radio-but"
                                id="rad3" (change)="initSurvey('3')">
                            <label for="rad3"></label>
                            <div class="check"></div>
                        </div>
                    </li>
                    <li>
                        <span class="rad-num">4</span>
                        <div class="radio-main">
                            <input type="radio" name="radio1" class="radio-but"
                                id="rad4" (change)="initSurvey('4')">
                            <label for="rad4"></label>
                            <div class="check"></div>
                        </div>
                    </li>
                    <li>
                        <span class="rad-num">5</span>
                        <div class="radio-main">
                            <input type="radio" name="radio1" class="radio-but"
                                id="rad5" (change)="initSurvey('5')">
                            <label for="rad5"></label>
                            <div class="check"></div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</div></div>`;
  }

  initSurveyFromPopup(val: any) {
    this.initSurvey(val);
  }

  errorMsg: any
  submitOfferCode() {
    if (!this.offerCode) {
      this.studentOfferForm.controls['offerCode'].markAsTouched();
      this.studentOfferForm.controls['offerCode'].setErrors({ incorrect: true });
      return;
    } else {
      this.apiService.submitStudentOffer(this.offerCode).then((result) => {
        if (result.status === 'FAILED') {
          this.errorMsg = result.desc;
          this.studentOfferForm.controls['offerCode'].markAsTouched();
          this.studentOfferForm.controls['offerCode'].setErrors({ incorrect: true });
          return;
        } else {
          window.location.replace('./redeem/campaign/' + result.campaignId + '/' + result.offerType + '?code=' + result.offerCode);
          this.offerCode = undefined;
          this.studentOfferModalRef.dismiss();
        }
      });
    }
  }

  submitpsuOfferCode() {

    if (!this.psuofferCode) {
      this.studentOfferForm.controls['psuofferCode'].markAsTouched();
      this.studentOfferForm.controls['psuofferCode'].setErrors({ incorrect: true });
      this.errorMsg = "Please enter offer code";
      return;
    } else {
      this.apac.submitPsuOffer(this.psuofferCode).then((result) => {
        if (result.status === 'FAILED') {
          this.errorMsg = result.desc;
          this.studentOfferForm.controls['psuofferCode'].markAsTouched();
          this.studentOfferForm.controls['psuofferCode'].setErrors({ incorrect: true });
          return;
        } else {
          window.location.replace('./redeem/campaign/' + result.campaignId + '/' + result.offerType + '?code=' + result.offerCode);
          this.offerCode = undefined;
          this.studentOfferModalRef.dismiss();
        }
      });
    }


  }

  selectSellerType(eve: any, sellertype: string, index: number) {

    this.appComp.loadMore = 0;
    // Exclusive, Multi Brand or Gaming
    if (sellertype === 'Reseller') {
      this.mapOffOn = false;
    } else {
      this.mapOffOn = true;
    }
    this.appComp.isLoad = true;
    this.appComp.index = index;
    // this.appComp.getContent();
    for (var i = 0; i < 4; i++) {
      this.stores = [];
      if (i == index) {

        document.getElementsByClassName('list')[index].classList.add("active");
        this.appComp.sellertype = sellertype;
        // if (this.appComp.latitude == undefined &&  this.appComp.longitude == undefined) {
        this.appComp.latitude = this.appComp.latitude ? this.appComp.latitude : localStorage.getItem('latitude');
        this.appComp.longitude = this.appComp.longitude ? this.appComp.longitude : localStorage.getItem('longitude');
        // }
        this.appComp.locationCheckingHome(this.appComp.latitude, this.appComp.longitude, true);
      } else {

        document.getElementsByClassName('list')[i].classList.remove("active");

      }
    }
  }


  selectSellerTypeOnload(eve: any, sellertype: string, index: number) {
    this.appComp.loadMore = 0;
    // Exclusive, Multi Brand or Gaming
    for (var i = 0; i < 4; i++) {
      this.stores = [];
      if (i == index) {
        document.getElementsByClassName('list')[index].classList.add("active");
        //mob
        document.getElementsByClassName('list1')[index].classList.add("active");
      } else {
        document.getElementsByClassName('list')[i].classList.remove("active");
        //mob
        document.getElementsByClassName('list1')[i].classList.remove("active");
      }
    }
  }
  mapOffOn = true
  selectSellerTypeMob(eve: any, sellertype: string, index: number) {
    this.appComp.loadMore = 0;
    if (sellertype === 'Reseller') {
      this.mapOffOn = false;
      $('.card-big-bx').addClass('adjusted');
    } else {
      this.mapOffOn = true;
      $('.card-big-bx').removeClass('adjusted');
    }

    // Exclusive, Multi Brand or Gaming
    this.appComp.isLoad = true;
    this.appComp.index = index;
    this.appComp.sellertype = sellertype;
    // this.appComp.getContent();
    for (var i = 0; i < 4; i++) {
      this.stores = [];
      if (i == index) {
        document.getElementsByClassName('list1')[i].classList.add("active");

        // if (this.appComp.latitude == undefined &&  this.appComp.longitude == undefined) {

        this.appComp.latitude = this.appComp.latitude ? this.appComp.latitude : localStorage.getItem('latitude');
        this.appComp.longitude = this.appComp.longitude ? this.appComp.longitude : localStorage.getItem('longitude');
        // }
        this.appComp.locationCheckingHome(this.appComp.latitude, this.appComp.longitude, true);
      } else {
        document.getElementsByClassName('list1')[i].classList.remove("active");

      }
    }
  }

  toggleTab(ev: any) {


    const el = ev.target;
    $(el).parents('.tab-thumb').find('li').removeClass('active');
    var clickedItem = $(el).attr('rel');
    $(el).parents('.tab-section').find(".tab-details").hide();
    $(el).parents('.tab-section').find('.tab-details.' + clickedItem).show();
    $(el).parent('li').addClass('active');
    return false;

  }
  getLang(): string {
    return this.appComp.localeId.split('-')[0];
  }

  formattedAddressLink(address: any) {
    // this.router.navigate( [this.appComp.countryName, this.appComp.localeId,"store", address]);
  }

  showCountries() {
    // this.appComp.countryListShow = true;
    // $('#countries').show().animate({ 'top': '10%' }, 600);
    if (window.innerWidth < 991) {
      // this.appComp.countryListShow = true;
      $('#countries').show().animate({ 'left': '0' }, 800);
    }


  }


  closeCallBook() {
    $('#callSlideSection').hide();
  }
  openCallBook() {
    $('#callSlideSection').show();
  }


}
